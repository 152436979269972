import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/pages/main';
import {getTopMatches, getTopLive, getUpcomingMatches, getLiveMatches, setLocation} from '../../../reducers/books/actions';
import {getCountToShow} from '../../../utils/viewport';

import LoaderPage from '../loaderPage';
import Popular from './popular';
import Schedule from './schedule';

export default () => {
    const [tab, setTab] = useState('popular'),
          {states, page, tickets} = useSelector(Selector),
          dispatch = useDispatch();

    const loaded = tab === 'popular' ? (states.location && states.top_live && states.top_matches && states.live && states.upcoming) : (states.location && states.upcoming)

    useEffect(() => {
        if(!loaded) {
            if(tab === 'popular') {
                console.log(states);
                if(!states.location) dispatch(setLocation('/'));
                if(!states.top_live) dispatch(getTopLive());
                if(!states.top_matches) dispatch(getTopMatches('all'));
                if(!states.live) dispatch(getLiveMatches('all', getCountToShow(), 0));
                if(!states.upcoming) dispatch(getUpcomingMatches('all', getCountToShow(), 0));
            } else {
                if(!states.location) dispatch(setLocation('/'));
                if(!states.upcoming) dispatch(getUpcomingMatches('all', 5e3, 0));
            }
        }
    }, [loaded, page.location]);

    if(!loaded)
        return <LoaderPage />

    return (
        <>
            {/* Tabs */}
            <div class="popular container">
                <input id="highlights" type="checkbox" checked={tab === 'popular'} />
                <label for="highlights" className={`popular__highlight ${tab === 'popular' ? 'popular__highlight-active' : ''}`} onClick={e => {
                    setTab('popular');
                    dispatch(setLocation('/'));
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M0 2.66658C0 1.19387 1.19387 0 2.66658 0H7.33309C8.8058 0 9.99966 1.19387 9.99966 2.66658V9.33302C9.99966 10.8057 8.8058 11.9996 7.33309 11.9996H2.66658C1.19387 11.9996 0 10.8057 0 9.33302V2.66658Z" fill="#FF9900"></path>
                        <path d="M11.3328 2.66658C11.3328 1.19387 12.5266 0 13.9993 0H17.3326C18.8053 0 19.9991 1.19387 19.9991 2.66658V5.33315C19.9991 6.80586 18.8053 7.99973 17.3326 7.99973H13.9993C12.5266 7.99973 11.3328 6.80586 11.3328 5.33315V2.66658Z" fill="#FF9900"></path>
                        <path d="M11.3325 11.9986C11.3325 10.5259 12.5264 9.33203 13.9991 9.33203H17.3323C18.805 9.33203 19.9989 10.5259 19.9989 11.9986V17.3318C19.9989 18.8045 18.805 19.9983 17.3323 19.9983H13.9991C12.5264 19.9983 11.3325 18.8045 11.3325 17.3318V11.9986Z" fill="#FF9900"></path>
                        <path d="M0 16.0001C0 14.5274 1.19387 13.3335 2.66658 13.3335H7.33309C8.8058 13.3335 9.99966 14.5274 9.99966 16.0001V17.3334C9.99966 18.8061 8.8058 19.9999 7.33309 19.9999H2.66658C1.19387 19.9999 0 18.8061 0 17.3334V16.0001Z" fill="#FF9900"></path>
                    </svg>
                    <p class="popular__highlight__p">Popular</p>
                </label>
                <input id="schedule" type="checkbox" checked={tab === 'schedule'} />
                <label for="schedule" className={`popular__highlight ${tab === 'schedule' ? 'popular__highlight-active' : ''}`} onClick={e => {
                    setTab('schedule');
                    dispatch(setLocation('/'));
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <rect x="1" y="1" width="18" height="18" rx="4" stroke="#05FF00" stroke-width="2"></rect>
                        <path d="M1.08796 5.66667C1.56027 3.01399 3.87827 1 6.66667 1H13.3333C16.1217 1 18.4397 3.01399 18.912 5.66667H1.08796Z" fill="#05FF00" stroke="#05FF00" stroke-width="2"></path>
                    </svg>
                    <p class="popular__highlight__p">Schedule</p>
                </label>
            </div>

            {tab === 'popular' ? <Popular page={page} tickets={tickets} /> : <Schedule page={page} tickets={tickets} />}
        </>
    )
}