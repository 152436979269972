import types from './types';
import API from '../../lib/api';
import {updateVisibleList} from '../../lib/websocket';

export const getSports = () => async dispatch => {
    const response = await API({
        path:'/sports'
    });

    if(response.success)
        dispatch({
            type: types.sports.loaded,
            payload: response.sports
        });
}

export const getCountrys = () => async dispatch => {
    const response = await API({
        path:'/countrys'
    });

    if(response.success)
        dispatch({
            type: types.countrys.loaded,
            payload: response.countrys
        });
}

export const getChampionships = () => async dispatch => {
    const response = await API({
        path:'/championships'
    });

    if(response.success)
        dispatch({
            type: types.championships.loaded,
            payload: response.championships
        });
}

export const updateMatches = (type) => {
    return {
        type: types.page.reset_matches,
        payload: type
    }
}

export const getEvent = game_id => async dispatch => {
    const response = await API({
        path:'/getEvent',
        body: {
            game_id
        }
    });

    if(response.success)
        dispatch({
            type: types.event.loaded,
            payload: response.event
        });
}

export const getEvents = () => async dispatch => {
    const response = await API({
        path:'/events'
    });

    if(response.success)
        dispatch({
            type: types.events.loaded,
            payload: response.events
        });
}

export const getTopMatches = sport => async dispatch => {
    const response = await API({
        path: '/getTopMatches',
        body: {
            sport
        }
    });

    if(response.success)
        dispatch({
            type: types.page.top_matches,
            payload: response.response
        });
}

export const getSearchMatches = query => async dispatch => {
    const response = await API({
        path: '/getSearchMatches',
        body: {
            query
        }
    });

    if(response.success)
        dispatch({
            type: types.page.search,
            payload: response.response
        });
}

export const getLiveMatches = (sport, count, offset) => async dispatch => {
    const response = await API({
        path: '/getLiveMatches',
        body: {
            sport,
            count,
            offset
        }
    });

    if(response.success)
        dispatch({
            type: types.page.live,
            payload: response.response
        });
}

export const getUpcomingMatches = (sport, count, offset) => async dispatch => {
    const response = await API({
        path: '/getUpcomingMatches',
        body: {
            sport,
            count,
            offset
        }
    });

    if(response.success)
        dispatch({
            type: types.page.upcoming,
            payload: response.response
        });
}

export const getTopLive = () => async dispatch => {
    const response = await API({
        path: '/getTopLive'
    });

    if(response.success)
        dispatch({
            type: types.page.top_live,
            payload: response.response
        });
}

export const setLocation = location => {
    return {
        type: types.page.location,
        payload: location
    }
}

export const loadMainPage = () => async dispatch => {
    const response = await API({
        path: '/main'
    });

    if(response.success)
        dispatch({
            type: types.page.loaded,
            payload: {
                location: '/'
            }
        });
}

export const addTicket = (ticketId, event) => dispatch => {
    dispatch({
        type: types.tickets.add,
        payload: {
            ticket: ticketId,
            event: event
        }
    });

    updateVisibleList();
}

export const removeTicket = (ticketId) => {
    return {
        type: types.tickets.remove,
        payload: ticketId
    }
}