import Stats from './stats';
import Bets from './bets';

export default () => (
    <>
        <div class="subtitle container bet__maxWidth">
            <div class="subtitle__image">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.8125 10.4687C7.8125 7.31764 10.3072 4.6875 13.4766 4.6875H27.1484C30.3178 4.6875 32.8125 7.31764 32.8125 10.4687V30.1562C32.8125 33.3073 30.3178 35.9375 27.1484 35.9375H13.4766C10.3072 35.9375 7.8125 33.3073 7.8125 30.1562V10.4687ZM13.4766 7.8125C12.1155 7.8125 10.9375 8.95995 10.9375 10.4687V30.1562C10.9375 31.665 12.1155 32.8125 13.4766 32.8125H27.1484C28.5095 32.8125 29.6875 31.665 29.6875 30.1562V10.4687C29.6875 8.95995 28.5095 7.8125 27.1484 7.8125H13.4766ZM34.9609 15.625C34.9609 14.7621 35.6605 14.0625 36.5234 14.0625H37.5C40.0889 14.0625 42.1875 16.1611 42.1875 18.75V40.625C42.1875 43.2139 40.0889 45.3125 37.5 45.3125H21.875C19.2861 45.3125 17.1875 43.2139 17.1875 40.625V39.5312C17.1875 38.6683 17.887 37.9688 18.75 37.9688C19.613 37.9688 20.3125 38.6683 20.3125 39.5312V40.625C20.3125 41.488 21.012 42.1875 21.875 42.1875H37.5C38.363 42.1875 39.0625 41.488 39.0625 40.625V18.75C39.0625 17.887 38.363 17.1875 37.5 17.1875H36.5234C35.6605 17.1875 34.9609 16.488 34.9609 15.625ZM15.625 25C14.7621 25 14.0625 25.6995 14.0625 26.5625C14.0625 27.4255 14.7621 28.125 15.625 28.125H25C25.863 28.125 26.5625 27.4255 26.5625 26.5625C26.5625 25.6995 25.863 25 25 25H15.625ZM14.0625 20.3125C14.0625 19.4495 14.7621 18.75 15.625 18.75H25C25.863 18.75 26.5625 19.4495 26.5625 20.3125C26.5625 21.1755 25.863 21.875 25 21.875H15.625C14.7621 21.875 14.0625 21.1755 14.0625 20.3125ZM15.625 12.5C14.7621 12.5 14.0625 13.1996 14.0625 14.0625C14.0625 14.9254 14.7621 15.625 15.625 15.625H25C25.863 15.625 26.5625 14.9254 26.5625 14.0625C26.5625 13.1996 25.863 12.5 25 12.5H15.625Z" fill="#3DD4F5"></path>
                </svg>
            </div>
            <h2 class="subtitle__text">Profile</h2>
        </div>
        <Stats />
        <Bets />
    </>
)