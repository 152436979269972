export default {
    loaded: 'session.loaded',
    error: 'session.error',
    balance: 'session.balance',
    stats: 'session.stats',
    bet: {
        add: 'session.bet.add',
        update: 'session.bet.update'
    },
    toggleButton: 'session.toggle.button'
}