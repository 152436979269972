import Slider from 'react-slick';
import LiveMatch from '../../../components/live_match';
import match from '../../match';

export default ({matches, tickets}) => {
    if(!matches.matches)
        return <></>;

    return (
        <>
            <div class="live container">
                <Slider 
                    className="slider" 
                    dots={true} 
                    arrows={false} 
                    infinite={true}
                    slidesToShow={3}
                    slidesToScroll={3}
                    variableWidth={true}
                    responsive={[
                        {
                            breakpoint: 1640,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2
                            }
                        },
                        {
                            breakpoint: 860,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]}>
                        {matches.matches.map((match, key) => {
                            return <LiveMatch match={match} key={key} tickets={tickets} />
                        })}
                </Slider>
            </div>
        </>
    )
}