export default {
    tickets: {
        add: 'books.tickets.add',
        remove: 'books.tickets.remove',
        clear: 'books.tickets.clear'
    },
    event: {
        loaded: 'books.event.loaded'
    },
    socket: 'books.socket',
    sports: {
        loaded: "books.sports.loaded",
    },
    championships: {
        loaded: "books.championships.loaded"
    },
    events: {
        loaded: "books.events.loaded",
        update: "books.events.update"
    },
    countrys: {
        loaded: "books.countrys.loaded"
    },
    page: {
        loaded: "books.page.loaded",
        location: "books.page.location",
        top_matches: "books.page.top_matches",
        live: "books.page.live",
        upcoming: "books.page.upcoming",
        top_live: "books.page.top_live",
        reset_matches: "books.page.reset_matches",
        search: "books.page.search"
    }
}