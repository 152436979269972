import {useSelector} from 'react-redux';
import Selector from '../../../selectors/pages/profile/stats';
import Numeric from '../../components/numeric';

export default () => {
    const {stats} = useSelector(Selector);

    return (
        <div class="container">
            <div class="bets__stats">
                <div class="bets__stat__block">
                    <div class="bets__stat">
                        <div class="bets__stat__info">
                            <div class="bets__stat__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="39" height="24" viewBox="0 0 39 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.66667 4.8C9.66667 2.14903 11.8306 0 14.5 0C17.1694 0 19.3333 2.14903 19.3333 4.8C19.3333 6.03005 18.8675 7.15202 18.1014 8.00138L22.5754 14.6662C23.0737 14.4937 23.6092 14.4 24.1667 14.4C24.7242 14.4 25.2597 14.4938 25.758 14.6663L30.232 8.00155C29.4659 7.15217 29 6.03012 29 4.8C29 2.14903 31.1639 0 33.8333 0C36.5028 0 38.6667 2.14903 38.6667 4.8C38.6667 7.45097 36.5028 9.6 33.8333 9.6C33.2758 9.6 32.7405 9.50628 32.2422 9.33384L27.7682 15.9987C28.5341 16.848 29 17.97 29 19.2C29 21.851 26.8361 24 24.1667 24C21.4973 24 19.3333 21.851 19.3333 19.2C19.3333 17.97 19.7992 16.848 20.5654 15.9985L16.0913 9.33377C15.593 9.50626 15.0575 9.6 14.5 9.6C13.9425 9.6 13.4072 9.50628 12.9089 9.33384L8.4348 15.9987C9.20083 16.848 9.66667 17.97 9.66667 19.2C9.66667 21.851 7.50271 24 4.83333 24C2.16396 24 0 21.851 0 19.2C0 16.549 2.16396 14.4 4.83333 14.4C5.39091 14.4 5.92644 14.4938 6.42478 14.6663L10.8988 8.00155C10.1326 7.15217 9.66667 6.03012 9.66667 4.8Z" fill="#808A97"></path>
                                    </svg>
                            </div>
                            <p class="bets__stat__name">Win Rate</p>
                        </div>
                        <p class="bets__stat__count">
                            <Numeric value={stats.winrate} displayType="text" prefix={false} thousandSeparator={' '} decimalSeparator={'.'} decimalScale={2} />%
                        </p>
                    </div>
                    <div class="bets__stat__line"></div>
                    <div class="bets__stat">
                        <div class="bets__stat__info">
                            <div class="bets__stat__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <g clip-path="url(#clip0_311_419)">
                                        <path d="M21.3559 3.64397C16.6937 -1.21453 8.3059 -1.21483 3.64395 3.64411C-1.2145 8.30556 -1.2148 16.6945 3.64395 21.356C8.30583 26.2147 16.6942 26.2145 21.356 21.356C26.2147 16.694 26.2145 8.30577 21.3559 3.64397ZM3.69021 11.7632H1.47425C1.63917 9.26966 2.63454 6.99973 4.18618 5.22797L5.75265 6.79445C4.59658 8.15945 3.84645 9.8782 3.69021 11.7632ZM18.2053 5.75262C16.8403 4.5966 15.1216 3.84642 13.2367 3.69019V1.47422C15.7301 1.6392 18.0001 2.63456 19.7719 4.18615L18.2053 5.75262ZM11.7632 3.69019C9.87826 3.84647 8.15944 4.5966 6.79448 5.75257L5.228 4.1861C6.99965 2.63446 9.26965 1.6392 11.7632 1.47417V3.69019ZM12.4999 5.13269C16.5622 5.13269 19.8671 8.43764 19.8671 12.4999C19.4967 22.2601 5.50157 22.2575 5.13267 12.4999C5.13267 8.43764 8.43764 5.13269 12.4999 5.13269ZM6.79448 19.2473C8.15951 20.4033 9.87826 21.1535 11.7632 21.3097V23.5256C9.26965 23.3607 6.99965 22.3654 5.228 20.8138L6.79448 19.2473ZM13.2367 21.3097C15.1216 21.1534 16.8403 20.4033 18.2053 19.2472L19.7719 20.8138C18.0002 22.3654 15.7302 23.3607 13.2367 23.5257V21.3097ZM19.2472 18.2054C20.4033 16.8404 21.1534 15.1217 21.3096 13.2367H23.5256C23.3607 15.7302 22.3653 18.0001 20.8137 19.7719L19.2472 18.2054ZM21.3097 11.7632C21.1534 9.8782 20.4033 8.15952 19.2473 6.7945L20.8138 5.22802C22.3654 6.99973 23.3608 9.26973 23.5257 11.7633L21.3097 11.7632ZM1.47425 13.2367H3.69021C3.8465 15.1217 4.59663 16.8404 5.75265 18.2054L4.18618 19.7719C2.63454 18.0002 1.63917 15.7302 1.47425 13.2367Z" fill="#6E7885"></path>
                                        <path d="M11.7617 14.5834V16.9203H13.2352V14.5834C13.8621 14.361 14.3596 13.8635 14.5819 13.2367H16.9188V11.7632H14.5819C14.3596 11.1363 13.8621 10.6388 13.2352 10.4165V8.07959H11.7617V10.4165C11.1349 10.6388 10.6374 11.1363 10.415 11.7632H8.07812V13.2367H10.415C10.6374 13.8635 11.1349 14.361 11.7617 14.5834ZM12.4985 11.7632C12.9047 11.7632 13.2352 12.0937 13.2352 12.4999C13.1982 13.4759 11.7986 13.4757 11.7617 12.4999C11.7617 12.0937 12.0922 11.7632 12.4985 11.7632Z" fill="#6E7885"></path>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_311_419">
                                        <rect width="25" height="25" fill="white"></rect>
                                        </clipPath>
                                    </defs>
                                    </svg>
                            </div>
                            <p class="bets__stat__name">Total Bets</p>
                        </div>
                        <p class="bets__stat__count">
                            <Numeric value={stats.total_bets} displayType="text" thousandSeparator={' '} decimalSeparator={','} decimalScale={2} />
                        </p>
                    </div>
                </div>
                <div class="bets__stat__lines">
                    <div class="bets__stat__line bets__stat__line_2"></div>
                    <div class="bets__stat__line bets__stat__line_3"></div>
                </div>
                <div class="bets__stat__block">
                <div class="bets__stat">
                        <div class="bets__stat__info">
                            <div class="bets__stat__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="32" viewBox="0 0 28 32" fill="none">
                                    <g clip-path="url(#clip0_311_424)">
                                        <path d="M14.0001 15.4288L26.8801 8.00021L14.0001 1.14307L1.12012 8.00021L14.0001 15.4288Z" fill="#6E7885"></path>
                                        <path d="M14 30.8571L26.88 22.8571V8L14 15.4286V30.8571Z" fill="#6E7885"></path>
                                        <path d="M1.12012 8V22.8571L14.0001 30.8571V15.4286L1.12012 8Z" fill="#6E7885"></path>
                                        <path d="M1.12006 8.00021L0.861022 7.49359L0.560059 7.65382V8.00021H1.12006ZM1.12006 22.8573H0.560059V23.1785L0.828889 23.3455L1.12006 22.8573ZM14.0001 30.8573L13.7089 31.3455L14.0001 31.5263L14.2913 31.3455L14.0001 30.8573ZM26.8801 22.8573L27.1713 23.3455L27.4401 23.1785V22.8573H26.8801ZM26.8801 8.00021H27.4401V7.65382L27.1391 7.49359L26.8801 8.00021ZM14.0001 1.14306L14.2591 0.636444L14.0001 0.498535L13.741 0.636444L14.0001 1.14306ZM0.560059 8.00021V22.8573H1.68006V8.00021H0.560059ZM0.828889 23.3455L13.7089 31.3455L14.2913 30.3692L1.41123 22.3692L0.828889 23.3455ZM14.2913 31.3455L27.1713 23.3455L26.5889 22.3692L13.7089 30.3692L14.2913 31.3455ZM27.4401 22.8573V8.00021H26.3201V22.8573H27.4401ZM26.6045 7.50274L13.7245 14.9313L14.2756 15.9263L27.1556 8.49767L26.6045 7.50274ZM14.2756 14.9313L1.39561 7.50274L0.844506 8.49767L13.7245 15.9263L14.2756 14.9313ZM27.1391 7.49359L14.2591 0.636444L13.741 1.64968L26.621 8.50682L27.1391 7.49359ZM13.741 0.636444L0.861022 7.49359L1.37909 8.50682L14.2591 1.64968L13.741 0.636444ZM13.4401 15.4288V30.8573H14.5601V15.4288H13.4401Z" fill="#1E2837"></path>
                                        <path d="M17.5182 19.427C16.5615 18.6054 18.9205 14.9341 20.1599 16.0001C21.3993 17.0662 18.6695 20.4156 17.5182 19.427Z" fill="#1E2837"></path>
                                        <path d="M11.4466 8.17631C11.3358 6.53979 16.4075 6.60906 16.3169 8.40874C16.2263 10.2084 11.5798 10.1456 11.4466 8.17631Z" fill="#1E2837"></path>
                                        <path d="M21.9976 21.7126C21.0394 20.8894 23.4601 17.9576 24.4171 18.7804C25.3743 19.6031 22.956 22.5361 21.9976 21.7126Z" fill="#1E2837"></path>
                                        <path d="M7.40548 21.1794C6.69661 21.3948 4.94004 18.831 5.85943 18.5527C6.77883 18.2744 8.25849 20.9204 7.40548 21.1794Z" fill="#1E2837"></path>
                                        <path d="M10.552 18.7326C9.7653 19.0134 8.49041 16.4964 9.2765 16.2161C10.0626 15.9358 11.3389 18.4516 10.552 18.7326Z" fill="#1E2837"></path>
                                        <path d="M4.39229 23.3038C3.60563 23.5846 2.33074 21.0677 3.11683 20.7874C3.90297 20.507 5.17924 23.0229 4.39229 23.3038Z" fill="#1E2837"></path>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_311_424">
                                        <rect width="28" height="32" fill="white"></rect>
                                        </clipPath>
                                    </defs>
                                    </svg>
                            </div>
                            <p class="bets__stat__name">Total Wins</p>
                        </div>
                        <p class="bets__stat__count">
                             <Numeric value={stats.total_wins} displayType="text" thousandSeparator={' '} decimalSeparator={','} decimalScale={2} />
                        </p>
                    </div>
                    <div class="bets__stat__line"></div>
                    <div class="bets__stat">
                        <div class="bets__stat__info">
                            <div class="bets__stat__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path d="M23.4049 27.7529H8.59632C8.05665 27.7536 7.5323 27.5735 7.10699 27.2413C6.68168 26.909 6.37994 26.444 6.24994 25.9202L3.96741 16.7926C3.89593 16.5062 3.89978 16.2062 3.97862 15.9218C4.05746 15.6374 4.20858 15.3782 4.41728 15.1695C4.62597 14.9608 4.88514 14.8097 5.16956 14.7309C5.45399 14.6521 5.75397 14.6482 6.04033 14.7196L9.68553 15.6311C9.76331 15.6505 9.84508 15.6472 9.92099 15.6214C9.99699 15.5956 10.0639 15.5486 10.1139 15.4859L14.1119 10.4886C14.346 10.217 14.6358 9.99891 14.9617 9.84949C15.2876 9.69998 15.6419 9.62265 16.0005 9.62256C16.359 9.62247 16.7134 9.6998 17.0393 9.84914C17.3653 9.99847 17.6552 10.2163 17.8893 10.488L21.8873 15.4866C21.9381 15.5488 22.0053 15.5953 22.0814 15.6209C22.1574 15.6465 22.239 15.65 22.317 15.6312L25.9603 14.7197C26.2466 14.6481 26.5466 14.6519 26.831 14.7306C27.1155 14.8094 27.3747 14.9604 27.5834 15.169C27.7922 15.3777 27.9434 15.6368 28.0223 15.9212C28.1013 16.2056 28.1052 16.5056 28.0338 16.792L25.7513 25.9209C25.6212 26.4445 25.3193 26.9094 24.8941 27.2415C24.4687 27.5736 23.9445 27.7536 23.4049 27.7529ZM6.02534 16.7776L8.19006 25.4352C8.21246 25.5259 8.26467 25.6066 8.33832 25.6642C8.41197 25.7218 8.50282 25.753 8.59632 25.7529H23.4049C23.4983 25.753 23.5891 25.7218 23.6627 25.6644C23.7364 25.6069 23.7886 25.5265 23.8111 25.4358L25.9758 16.7776L22.8014 17.5712C22.3521 17.6828 21.8803 17.6634 21.4416 17.5154C21.003 17.3674 20.6159 17.097 20.3262 16.7359L16.3281 11.7372C16.1094 11.4651 15.8919 11.4645 15.6732 11.7379L11.6751 16.7353C11.3855 17.0962 10.9987 17.3668 10.5603 17.5149C10.1219 17.6631 9.65024 17.6826 9.20117 17.5712L6.02534 16.7776Z" fill="#6E7885"></path>
                                    <path d="M3.6665 13.6665C4.77107 13.6665 5.6665 12.771 5.6665 11.6665C5.6665 10.562 4.77107 9.6665 3.6665 9.6665C2.56194 9.6665 1.6665 10.562 1.6665 11.6665C1.6665 12.771 2.56194 13.6665 3.6665 13.6665Z" fill="#6E7885"></path>
                                    <path d="M28.3335 13.6665C29.438 13.6665 30.3335 12.771 30.3335 11.6665C30.3335 10.562 29.438 9.6665 28.3335 9.6665C27.229 9.6665 26.3335 10.562 26.3335 11.6665C26.3335 12.771 27.229 13.6665 28.3335 13.6665Z" fill="#6E7885"></path>
                                    <path d="M16 8C17.1045 8 18 7.10457 18 6C18 4.89543 17.1045 4 16 4C14.8955 4 14 4.89543 14 6C14 7.10457 14.8955 8 16 8Z" fill="#6E7885"></path>
                                    </svg>
                            </div>
                            <p class="bets__stat__name">Total Profit</p>
                        </div>
                        <p class="bets__stat__count">
                            <Numeric value={stats.total_profit} displayType="text" thousandSeparator={' '} decimalSeparator={','} decimalScale={2} />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}