import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/pages/search';
import {setLocation, getSearchMatches} from '../../../reducers/books/actions';
import LoaderPage from '../../pages/loaderPage';
import Tags from '../../components/tags';
import TagsChampionships from '../../components/tags_championships';
import Match from '../../components/match';

export default () => {
    const {loaded, states, page, tickets} = useSelector(Selector),
          dispatch = useDispatch(),
          [state, setState] = useState({sport: 'all', championship: 'all', count: 9}),
          [search, setSearch] = useState('');

    useEffect(() => {
        if(page.location !== `/search`) dispatch(setLocation(`/search`));

        if(!loaded && page.location === `/search`) {
            dispatch(getSearchMatches())
        } 
    }, [loaded, page.location]);

    if(!loaded)
        return <LoaderPage />

    const matches = page.search.matches ? page.search.matches.filter(match => {
        const isChampionship = match.championship.toLowerCase().indexOf(search.toLowerCase()) >= 0,
              isOpp1 = match.opp1.name_en.toLowerCase().indexOf(search.toLowerCase()) >= 0,
              isOpp2 = match.opp1.name_en.toLowerCase().indexOf(search.toLowerCase()) >= 0;

        return isChampionship || isOpp1 || isOpp2;
    }) : []

    const sports = {};
    for(const match of matches) {
        if(typeof sports[match.sport.sportId.toString()] === 'undefined')
            sports[match.sport.sportId.toString()] = {
                name: match.sport.name,
                count: 0
            }

        sports[match.sport.sportId.toString()].count++;
    }

    const sports_array = [];
    for(const sportId of Object.keys(sports)) {
        sports_array.push({
            sportId: parseInt(sportId),
            ...sports[sportId]
        });
    }

    const championships = {};
    for(const m of matches.filter(match => state.sport === 'all' || match.sport.sportId === state.sport)) {
        if(typeof championships[m.championship] === 'undefined')
            championships[m.championship] = 0;

        championships[m.championship]++;
    }

    const matches_to_show = matches.filter(match => {
        const sport = state.sport === 'all' || match.sport.sportId === state.sport,
              championship = state.championship === 'all' || match.championship === state.championship;

        return sport && championship;
    }).filter((_, key) => key < state.count);

    return (
        <div class="container">
            <div class="search__input__block">
                <div class="search__input__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 17.5C12.5 12.6675 16.4175 8.75 21.25 8.75C26.0825 8.75 30 12.6675 30 17.5C30 22.3325 26.0825 26.25 21.25 26.25C16.4175 26.25 12.5 22.3325 12.5 17.5ZM21.25 6.25C15.0367 6.25 9.99999 11.2868 9.99999 17.5C9.99999 20.3658 11.0715 22.9811 12.8358 24.9676L6.61611 31.1871C6.12796 31.6754 6.12796 32.4668 6.61611 32.955C7.10427 33.4431 7.89572 33.4431 8.38389 32.955L14.6949 26.644C16.5406 27.9695 18.8041 28.75 21.25 28.75C27.4632 28.75 32.5 23.7133 32.5 17.5C32.5 11.2868 27.4632 6.25 21.25 6.25Z" fill="#AFAFAF"></path>
                    </svg>
                </div>
                <input placeholder="Search" class="search__input" type="text" onInput={e => {
                    setSearch(e.target.value);
                    setState({sport: 'all', championship: 'all', count: 9});
                }} />
            </div>

            <Tags sports={sports_array} current_tag={state.sport} onClickHandler={value => {
                setState({
                    ...state,
                    sport: value,
                    championship: 'all',
                    count: 9
                });
            }} />
            <TagsChampionships
                championships={championships}
                current_tag={state.championship}
                onClickHandler={tab => {
                    setState({
                        ...state,
                        championship: tab,
                        count: 9
                    });
                }} />
                
            {matches_to_show.length > 0 ? (
                <div class="container">
                    <div 
                        className="match__slider">
                        {matches_to_show.map((match, key) => <Match match={match} key={key} isLive={match._.type === "live"} tickets={tickets} />)}
                    </div>
                    {(matches.length > state.count && state.count < 100) && (
                        <a class="match__showAll" href="#" onClick={e => {
                            e.preventDefault();

                            if(state.count < 100)
                                setState({
                                    ...state,
                                    count: state.count + 9
                                })
                        }}>
                            <p>Show more</p>
                        </a>
                    )}
                </div>
            ) : (
                <>
                    <div class="search__empty">
                        <div class="search__empty__img">
                            <svg xmlns="http://www.w3.org/2000/svg" width="177" height="182" viewBox="0 0 177 182" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M42.1428 75.6342C42.1428 43.1451 68.558 16.8076 101.143 16.8076C133.728 16.8076 160.143 43.1451 160.143 75.6342C160.143 108.123 133.728 134.461 101.143 134.461C68.558 134.461 42.1428 108.123 42.1428 75.6342ZM101.143 0C59.2478 0 25.2856 33.8626 25.2856 75.6342C25.2856 94.9007 32.5106 112.484 44.4068 125.839L2.46864 167.653C-0.822881 170.936 -0.822881 176.256 2.46864 179.539C5.76025 182.82 11.0969 182.82 14.3885 179.539L56.9426 137.11C69.3882 146.021 84.6507 151.268 101.143 151.268C143.038 151.268 177 117.406 177 75.6342C177 33.8626 143.038 0 101.143 0Z" fill="#737A87"></path>
                            </svg>
                        </div>
                        <p class="search__empty__question search__empty__question_1">?</p>
                        <p class="search__empty__question search__empty__question_2">?</p>
                        <p class="search__empty__question search__empty__question_3">?</p>
                        <p class="search__empty__question search__empty__question_4">?</p>
                    </div>
                    <p class="search__empty__text">Could not find events according to your request</p>
                </>
            )}
        </div>
    )
}