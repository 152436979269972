import moment from 'moment';

const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
];

export const parseString = timestamp => {
    const today = new Date().getDate(),
          tomorrow = new Date(new Date().getTime() + (24 * 60 * 60 * 1e3)).getDate();

    timestamp = Math.ceil(timestamp / 1e3) * 1e3;

    const time = new Date(timestamp);

    let string = '';
    if(time.getDate() == today) string = 'Today, ';
    else if(time.getDate() == tomorrow) string = 'Tomorrow, ';
    else string = `${time.getDate()} ${months[time.getMonth()]}, `;

    string += moment(time).format('hh:mm A');

    return `${string}`;
}

export const parseDate = (timestamp) => {
    let timeleft = timestamp - Date.now();

    if(timeleft <= 0)
        return {
            days: [0, 0],
            hours: [0, 0],
            minutes: [0, 0],
            seconds: [0, 0]
        }

    const days = Math.floor(timeleft / (24 * 60 * 60 * 1e3));
    timeleft -= days * (24 * 60 * 60 * 1e3);
    const hours = Math.floor(timeleft / (60 * 60 * 1e3));
    timeleft -= hours * (60 * 60 * 1e3);
    const minutes = Math.floor(timeleft / (60 * 1e3));
    timeleft -= minutes * (60 * 1e3);
    const seconds = Math.floor(timeleft / 1e3);

    return {
        days: (days < 10 ? `0${days}` : days.toString()).split(''),
        hours: (hours < 10 ? `0${hours}` : hours.toString()).split(''),
        minutes: (minutes < 10 ? `0${minutes}` : minutes.toString()).split(''),
        seconds: (seconds < 10 ? `0${seconds}` : seconds.toString()).split('')
    }
}