export const getRates = (rates) => {
    const draw_exist = rates.filter(rate => rate.oc_group_name === '1x2' && rate.oc_name === 'Draw')[0] || false,
          oneXtwo = rates.filter(rate => rate.oc_group_name === '1x2' && rate.oc_name !== 'Draw');

    if(draw_exist && oneXtwo.length === 2) {
        return [
            {...oneXtwo[0], oc_name: 'x1'},
            draw_exist,
            {...oneXtwo[1], oc_name: 'x2'},
        ]
    }

    if(!draw_exist && oneXtwo.length === 2)
        return [
            {...oneXtwo[0], oc_name: 'x1'},
            {...oneXtwo[1], oc_name: 'x2'},
        ]

    return [];
}

export const formatRates = rates => {
    const list = {};

    for(const rate of rates) {
        if(typeof list[rate.oc_group_name] === 'undefined')
            list[rate.oc_group_name] = [];

        list[rate.oc_group_name].push({
            oc_name: rate.oc_name,
            oc_rate: rate.oc_rate,
            oc_pointer: rate.oc_pointer
        });
    }

    return list;
}

export const matchesFormat = (matches, championships, countrys, sports) => {
    if(!matches)
        return {matches: null};

    return {
        ...matches,
        matches: matches.matches.map(event => {
            const championship = championships.filter(champ => champ.championshipId == event.championshipId)[0] || false,
                  country = countrys.filter(country => country.countryId == event.countryId)[0] || false,
                  sport = sports.filter(s => s.sportId == event.sportId)[0] || false;
    
            return (championship && country && sport) ? {
                eventId: event.game_id,
                championship: championship.name,
                country: country.name,
                sport,
                sportId: event.sportId,
                rates: getRates(event.game_oc.list || [], event.opp1, event.opp2),
                opp1: event.opp1,
                opp2: event.opp2,
                score: event.score,
                game_id: event.game_id,
                game_start: event.game_start,
                timer: event.timer,
                _: event
            } : false
        }).filter(m => m),
        sports: matches.sports ? matches.sports.map(sport => {
            const s = sports.filter(sp => sp.sportId == sport.sportId)[0] || false;

            return s ? {
                ...s,
                count: sport.count
            } : false
        }).filter(s => s) : null,
        sport: sports.filter(s => s.sportId === matches.sportId)[0] || false
    }
}