import {dispatch, getState} from './store';
import Types from '../reducers/types';

let connection = null,
    connection_type = null;

let state = {
    initialized: false,
    authorized: false,
    providerId: null
}

const log = log => console.log(`[WS] ${log}`),
      send = data => {
        if(connection.readyState === WebSocket.OPEN)
            connection.send(JSON.stringify(data));
        else
            setTimeout(send, 1e3, data);
      }

function authorize(providerId = null) {
    if(providerId)
        state.providerId = providerId;

    send({
        type: 'authorize',
        payload: providerId
    });
}

const connect = () => {
    connection = new WebSocket(`${window.location.origin.replace('http', 'ws')}/ws`);

    connection.onopen = () => {
        log(`Connection open`);
        dispatch({type: Types.books.socket, payload: true});

        if(state.providerId)
            authorize();
    }

    connection.onmessage = res => {
        try {
            res = JSON.parse(res.data);
        } catch(e) {
            return log(`Wrong response`, res);
        }

        switch(res.type) {
            case 'bet.update':
                return dispatch({
                    type: Types.session.bet.update,
                    payload: res.payload
                });
            case 'balance':
                return dispatch({
                    type: Types.session.balance,
                    payload: res.payload
                });
            case 'stats':
                return dispatch({
                    type: Types.session.stats,
                    payload: res.payload
                });
            case 'event.update':
                return dispatch({
                    type: Types.books.events.update,
                    payload: res.payload
                });
        }
    }

    connection.onclose = () => {
        log('Connection closed');
        dispatch({type: Types.books.socket, payload: false});
        return setTimeout(connect, 3e3);
    }
}

function init() {
    if(state.initialized)
        return;

    state.initialized = true;
    connect();
}

let visible_send_timeout = null,
    visible_list = [];

const updateList = () => {
    send({
        type: 'visible.update',
        payload: visible_list
    });
}

export const resetVisibleList = () => {
    visible_list = [];
    updateList();
};

export const updateVisibleList = (matchId=null) => {
    clearTimeout(visible_send_timeout);

    if(matchId)
        if(!visible_list.includes(matchId))
            visible_list.push(matchId);

    const tickets_matches = getState();
    for(const event of tickets_matches.books.events)
        if(!visible_list.includes(event.game_id))
            visible_list.push(event.game_id.toString());

    visible_send_timeout = setTimeout(updateList, 3e2);
}

export default {
    init,
    authorize
}