export default () => (
    <div style={{
        width: '100vw',
        height: 'calc(100vh - 20px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        <span class="loader"></span>
    </div>
)