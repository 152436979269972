import {createSelector} from 'reselect';
import props from '../../props';
import {matchesFormat} from '../../formats';


export default createSelector(
    props.books.page,
    props.books.championships,
    props.books.countrys,
    props.books.sports,
    props.books.tickets,
    (page, championships, countrys, sports, tickets) => {
        const isPageLocation = page.location === '/',
              isTopLiveLoaded = page.top_live !== false,
              isLiveLoaded = page.live !== false,
              isTopMatchesLoaded = page.top_matches !== false,
              isUpcomingLoaded = page.upcoming !== false;

        return {
            loaded: isPageLocation && isLiveLoaded && isTopLiveLoaded && isTopMatchesLoaded && isUpcomingLoaded,
            states: {
                location: isPageLocation,
                live: isLiveLoaded,
                top_matches: isTopMatchesLoaded,
                top_live: isTopLiveLoaded,
                upcoming: isUpcomingLoaded
            },
            page: {
                location: page.location,
                top_live: isTopLiveLoaded ? matchesFormat(page.top_live, championships, countrys, sports) : [],
                top_matches: isTopMatchesLoaded ? matchesFormat(page.top_matches, championships, countrys, sports) : [],
                live: isLiveLoaded ? matchesFormat(page.live, championships, countrys, sports) : [],
                upcoming: isUpcomingLoaded ? matchesFormat(page.upcoming, championships, countrys, sports) : [],
            },
            tickets
        }
    }
)