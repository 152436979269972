import {createSelector} from 'reselect';
import props from './props';

export default createSelector(
    props.books.championships,
    props.books.countrys,
    props.books.sports,
    props.books.socket,
    props.session.loaded,
    props.session.error,
    (championships, countrys, sports, socket, session, sessionError) => {
        return {
            // loaded: (championships !== null) && (countrys !== null) && (sports !== null) && socket && session,
            loaded: (championships !== null) && (countrys !== null) && (sports !== null) && session,
            states: {
                championships: championships !== null,
                countrys: countrys !== null,
                sports: sports !== null,
                session
            },
            sessionError
        }
    }
)