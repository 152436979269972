import LiveTop from './live_top';
import Top from './top';
import Live from './live';
import Recent from './recent';

export default ({page, tickets}) => (
    <>
        <LiveTop matches={page.top_live} tickets={tickets} />
        <Top matches={page.top_matches} tickets={tickets} />
        <Live matches={page.live} tickets={tickets} />
        <Recent matches={page.upcoming} tickets={tickets} />
    </>
)