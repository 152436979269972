import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.books.tickets,
    props.books.events,
    props.books.sports,
    props.books.championships,
    props.session.buttons.create_coupon,
    (tickets, events, sports, championships, button) => {
        const list = [];
        for(const ocCounter of tickets) {
            let counter_object = null,
                event_object = null;

            for(const event of events)
                for(const counter of event.game_oc.list)
                    if(!counter_object && counter.oc_pointer === ocCounter) {
                        counter_object = counter;
                        event_object = event;
                    }

            if(counter_object && event_object) {
                const sport = sports.filter(s => s.sportId == event_object.sportId)[0] || false,
                      championship = championships.filter(c => c.championshipId == event_object.championshipId)[0] || false;

                if(sport && championship)
                    list.push({
                        oc_counter: ocCounter,
                        event: event_object,
                        counter: counter_object,
                        sport,
                        championship
                    });
            }
        }

        return {
            tickets: list.sort((a, b) => {
                if(a.event.game_id < b.event.game_id) return -1;
                if(a.event.game_id > b.event.game_id) return 1;
                return 0;
            }).map(item => {
                const combined_exist = list.filter(i => i.event.game_id == item.event.game_id && i.oc_counter !== item.oc_counter)[0] || false;

                return {
                    ...item,
                    combined: combined_exist ? true : false
                }
            }),
            button
        }
    }
)