import types from './types';

const InitialState = {
    sports: null,
    countrys: null,
    championships: null,
    event: null,
    tickets: [],
    events: [],
    page: {
        location: null,
        top_matches: false,
        top_live: false,
        live: false,
        upcoming: false,
        search: false
    },
    socket: false
}

export default (state = InitialState, {type, payload}) => {
    switch(type) {
        case types.tickets.clear:
            return {
                ...state,
                tickets: [],
                events: []
            }
        case types.events:
            return {
                ...state,
                events: [
                    ...state.events
                ]
            }
        case types.tickets.add:
            return {
                ...state,
                tickets: [
                    ...state.tickets,
                    payload.ticket
                ],
                events: [
                    ...state.events,
                    payload.event
                ]
            }
        case types.tickets.remove:
            return {
                ...state,
                tickets: state.tickets.filter(ticket => ticket !== payload)
            }
        case types.events.update:
            return {
                ...state,
                event: (state.event && state.event.game_id == payload.game_id) ? {
                    ...payload,
                    game_start: payload.game_start + Date.now(),
                    found: true
                } : state.event,
                // events: state.events ? state.events.map(event => {
                //     if(event.game_id === payload.game_id)
                //         return {
                //             ...payload,
                //             game_start: payload.game_start + Date.now()
                //         }
                    
                //     return event;
                // }) : state.events,
                page: {
                    ...state.page,
                    top_live: state.page.top_live ? {
                        ...state.page.top_live,
                        matches: state.page.top_live.matches.map(m => {
                            return m.game_id === payload.game_id ? {
                                ...payload,
                                game_start: payload.game_start + Date.now()
                            } : m;
                        })
                    } : state.page.live,
                    live: state.page.live ? {
                        ...state.page.live,
                        matches: state.page.live.matches.map(m => {
                            return m.game_id === payload.game_id ? {
                                ...payload,
                                game_start: payload.game_start + Date.now()
                            } : m;
                        })
                    } : state.page.live,
                    top_matches: state.page.top_matches ? {
                        ...state.page.top_matches,
                        matches: state.page.top_matches.matches.map(m => {
                            return m.game_id === payload.game_id ? {
                                ...payload,
                                game_start: payload.game_start + Date.now()
                            } : m;
                        })
                    } : state.page.top_matches,
                    upcoming: state.page.upcoming ? {
                        ...state.page.upcoming,
                        matches: state.page.upcoming.matches.map(m => {
                            return m.game_id === payload.game_id ? {
                                ...payload,
                                game_start: payload.game_start + Date.now()
                            } : m;
                        })
                    } : state.page.upcoming
                }
            }
        case types.event.loaded:
            return {
                ...state,
                event: {
                    ...payload,
                    game_start: payload.game_start ? payload.game_start + Date.now() : null
                }
            }
        case types.page.reset_matches:
            return {
                ...state,
                page: {
                    ...state.page,
                    [payload]: null
                }
            }
        case types.page.location:
            return {
                ...state,
                page: (payload === '/' || payload === '/live') ? {
                    location: payload,
                    top_live: false,
                    top_matches: false,
                    live: false,
                    upcoming: false
                } : {
                    location: payload
                }
            }
        case types.page.top_matches:
            return {
                ...state,
                page: {
                    ...state.page,
                    top_matches: {
                        ...payload,
                        matches: payload.matches.map(match => {
                            return {
                                ...match,
                                game_start: match.game_start + Date.now()
                            }
                        })
                    }
                }
            }
        case types.page.search:
            return {
                ...state,
                page: {
                    ...state.page,
                    search: {
                        ...payload,
                        matches: payload.matches.map(match => {
                            return {
                                ...match,
                                game_start: match.game_start + Date.now()
                            }
                        })
                    }
                }
            }
        case types.page.top_live:
            return {
                ...state,
                page: {
                    ...state.page,
                    top_live: {
                        ...payload,
                        matches: payload.matches.map(match => {
                            return {
                                ...match,
                                game_start: match.game_start + Date.now()
                            }
                        })
                    }
                }
            }
        case types.page.live:
            return {
                ...state,
                page: {
                    ...state.page,
                    live: {
                        ...payload,
                        matches: payload.matches.map(match => {
                            return {
                                ...match,
                                game_start: match.game_start + Date.now()
                            }
                        })
                    }
                }
            }
        case types.page.live:
            return {
                ...state,
                page: {
                    ...state.page,
                    live: {
                        ...payload,
                        matches: payload.matches.map(match => {
                            return {
                                ...match,
                                game_start: match.game_start + Date.now()
                            }
                        })
                    }
                }
            }
        case types.page.upcoming:
            return {
                ...state,
                page: {
                    ...state.page,
                    upcoming: {
                        ...payload,
                        matches: payload.matches.map(match => {
                            return {
                                ...match,
                                game_start: match.game_start + Date.now()
                            }
                        })
                    }
                }
            } 
        case types.page.loaded:
            return {
                ...state,
                page: payload
            }
        case types.socket:
            return {
                ...state,
                socket: payload
            }
        case types.sports.loaded:
            return {
                ...state,
                sports: payload
            }
        case types.championships.loaded:
            return {
                ...state,
                championships: payload
            }
        case types.countrys.loaded:
            return {
                ...state,
                countrys: payload
            }
        default:
            return state;
    }
}