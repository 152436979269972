import SportIcon from './sport_icon';

export default ({sports, onClickHandler, current_tag, showCount=true}) => {
    if(!sports)
        return <></>;
        
    return (
        <div class="tags-draggable container">
            <div class="tags">
                {sports.map((sport, key) => {
                    return (
                        <div class={`tags__item ${current_tag === sport.sportId ? 'tags__item-active' : ''}`} key={key} checked={current_tag === sport.sportId ? true : false} onClick={e => onClickHandler(current_tag === sport.sportId ? 'all' : sport.sportId)}>
                            <input type="checkbox" />
                            <label>
                                <div class="tags__item__img">
                                    <SportIcon sportId={sport.sportId} />   
                                </div>
                                <p class="tags__item__name">{sport.name}</p>
                                {showCount && <p class="tags__item__value">{sport.count}</p>}
                            </label>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}