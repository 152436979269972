import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/pages/match';
import {getEvent, addTicket, removeTicket} from '../../../reducers/books/actions';
import {useParams, useNavigate} from 'react-router-dom';
import LoaderPage from '../loaderPage';
import { useEffect, useState } from 'react';
import SportIcon from '../../components/sport_icon';
import Timer from './timer';
import Rate from '../../components/rate';
import {updateVisibleList} from '../../../lib/websocket';
import NotFound from './notFound';

export default () => {
    const {event, sport, championship, tickets} = useSelector(Selector),
          [hidden, setHidden] = useState([]),
          {game_id} = useParams(),
          dispatch = useDispatch(),
          navigate = useNavigate();

    const loaded = event && event.game_id == game_id;

    useEffect(() => {
        if(!loaded)
            dispatch(getEvent(game_id));
        else
            updateVisibleList(game_id);
    }, [loaded, game_id]);

    if(!loaded)
        return <LoaderPage />;

    if(!event.found)
        return <NotFound />;

    return (
        <div class="container currentMatch" data-hidden-count={hidden.length} style={{
            marginTop: '20px',
            maxWidth: event.type === 'live' ? '1920px' : '1230px',

        }}>
            <div class="currentMatch__content">
                <div class="currentMatch__info">
                    <div class="currentMatch__info__tournament">
                        <div class="currentMatch__info__tournament__img">
                            <SportIcon sportId={sport.sportId} />
                        </div>
                        <p class="currentMatch__info__tournament__name" onClick={e => navigate(`/sports/${sport.sportId}`)}>
                            {sport.name}
                        </p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="5" height="8" viewBox="0 0 5 8" fill="none" style={{
                            marginLeft: '5px',
                            marginRight: '4px'
                        }}>
                            <path d="M1.71 7.28997L4.3 4.69997C4.3927 4.60746 4.46625 4.49757 4.51643 4.3766C4.56661 4.25562 4.59244 4.12594 4.59244 3.99497C4.59244 3.864 4.56661 3.73432 4.51643 3.61335C4.46625 3.49237 4.3927 3.38249 4.3 3.28997L1.71 0.699971C1.08 0.0799712 0 0.519971 0 1.40997V6.57997C0 7.47997 1.08 7.91997 1.71 7.28997Z" fill="#9FA4AC"/>
                        </svg>
                        <p class="currentMatch__info__tournament__name">
                            {championship.name}
                        </p>
                    </div>
                    <div class="currentMatch__info__teams">
                        <div class="currentMatch__info__team">
                            <img class="currentMatch__info__team__img" src={`https://cdn.incub.space/v1/opp/icon/${event.opp1.icon.split('.png')[0]}.png`} alt="" />
                            <p class="currentMatch__info__team__name">{event.opp1.name_en}</p>
                        </div>
                        <div class="currentMatch__info__team">
                            <img class="currentMatch__info__team__img" src={`https://cdn.incub.space/v1/opp/icon/${event.opp2.icon.split('.png')[0]}.png`} alt="" />
                            <p class="currentMatch__info__team__name">{event.opp2.name_en}</p>
                        </div>
                    </div>

                    {event.type === 'live' ? (
                        <div class="currentMatch__score">
                            <div class="currentMatch__score__live">
                                <div class="currentMatch__score__live__img">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                        <path d="M19.5036 13.5866C19.4907 13.5784 19.4771 13.5711 19.4633 13.5647L13.0674 10.2715C12.5552 9.97577 11.9455 9.97668 11.4346 10.2715C10.9237 10.5672 10.6184 11.0955 10.6184 11.686V18.3144C10.6184 18.9049 10.9237 19.4332 11.4346 19.7289C11.6951 19.879 11.9812 19.9523 12.2617 19.9523C12.5318 19.9523 12.7964 19.8845 13.0281 19.7499L19.5036 16.4138C20.0145 16.1181 20.3193 15.5898 20.3193 15.0002C20.3193 14.4106 20.0145 13.8824 19.5036 13.5866ZM12.4934 12.0843L18.1559 15.0002L12.4934 17.918V12.0843Z" fill="#F73E3E"></path>
                                        <path d="M24.2859 5.71948C23.9197 5.35327 23.3264 5.35327 22.9602 5.71948C22.594 6.08569 22.594 6.67896 22.9602 7.04516C25.0815 9.16641 26.2497 11.9917 26.2497 15.0002C26.2497 18.0086 25.0815 20.8339 22.9602 22.9552C22.594 23.3214 22.594 23.9147 22.9602 24.2809C23.1433 24.464 23.3832 24.5556 23.6231 24.5556C23.8629 24.5556 24.1028 24.464 24.2859 24.2809C26.7615 21.8062 28.1247 18.5103 28.1247 15.0002C28.1247 11.4901 26.7615 8.19416 24.2859 5.71948Z" fill="#F73E3E"></path>
                                        <path d="M21.5938 8.41259C21.2275 8.04639 20.6343 8.04639 20.2681 8.41259C19.9018 8.77884 19.9018 9.37209 20.2681 9.73826C23.1693 12.6396 23.1693 17.3601 20.2681 20.2613C19.9018 20.6276 19.9018 21.2208 20.2681 21.587C20.4512 21.7702 20.691 21.8617 20.9309 21.8617C21.1708 21.8617 21.4107 21.7702 21.5938 21.587C23.3507 19.8302 24.3183 17.491 24.3183 14.9998C24.3183 12.5087 23.3507 10.1695 21.5938 8.41259Z" fill="#F73E3E"></path>
                                        <path d="M7.03903 7.04516C7.40524 6.67896 7.40524 6.08569 7.03903 5.71948C6.67283 5.35327 6.07956 5.35327 5.71335 5.71948C3.23822 8.19416 1.875 11.491 1.875 15.0002C1.875 18.5094 3.23822 21.8062 5.71335 24.2809C5.89645 24.464 6.13632 24.5556 6.37619 24.5556C6.61606 24.5556 6.85593 24.464 7.03903 24.2809C7.40524 23.9147 7.40524 23.3214 7.03903 22.9552C2.65274 18.5689 2.65274 11.4315 7.03903 7.04516Z" fill="#F73E3E"></path>
                                        <path d="M9.73116 8.41259C9.365 8.04639 8.77166 8.04639 8.40549 8.41259C6.64813 10.1695 5.68042 12.5087 5.68042 14.9998C5.68042 17.491 6.64813 19.8302 8.40549 21.587C8.58858 21.7702 8.82849 21.8617 9.06833 21.8617C9.30816 21.8617 9.54808 21.7702 9.73116 21.587C10.0974 21.2208 10.0974 20.6276 9.73116 20.2613C8.32812 18.8588 7.55542 16.9902 7.55542 14.9998C7.55542 13.0095 8.32812 11.1408 9.73116 9.73826C10.0974 9.37209 10.0974 8.77884 9.73116 8.41259Z" fill="#F73E3E"></path>
                                    </svg>
                                </div>
                                <p class="currentMatch__score__live__time">{Math.floor(event.timer/60)}' {event.score.period_name}</p>
                            </div>
                            <div class="currentMatch__score__teams">
                                <div class="currentMatch__score__team">
                                    <p>{event.score.full.split(':')[0]}</p>
                                </div>
                                <p class="currentMatch__score__p">:</p>
                                <div class="currentMatch__score__team">
                                    <p>{event.score.full.split(':')[1]}</p>
                                </div>
                            </div>
                            <p class="currentMatch__score__previous"> 
                                {event.score.period.split(';').map((score, key) => {
                                    return key === (event.score.period.split(';').length - 1) ? (
                                        <span class="currentMatch__score__previous__span">{score};</span>
                                    ) : (
                                        <span>{score};</span>
                                    )
                                })}
                            </p>
                        </div>
                    ) : <Timer game_start={event.game_start} />}
                </div>
                {Object.keys(event.game_oc.list).sort((a,b) => a.localeCompare(b)).map((name, key) => {
                    return (
                        <>
                            <div class="currentMatch__content__title__block currentMatch__content__firstTitle">
                                <p class="currentMatch__content__title">{name}</p>
                            </div>
                            {['1x2'].includes(name) ? (
                                <div class="currentMatch__coefficients">
                                    {event.game_oc.list[name].map((rate, key2) => {
                                        return (
                                            <button class={`currentMatch__coefficient ${tickets.includes(rate.oc_pointer) ? 'currentMatch__coefficient-active' : ''}`} key={key2} onClick={e => {
                                                dispatch(tickets.includes(rate.oc_pointer) ? removeTicket(rate.oc_pointer) : addTicket(rate.oc_pointer, event._));
                                            }}>
                                                <Rate value={rate.oc_rate} />
                                                <p class="currentMatch__coefficient__name">{rate.oc_name}</p>
                                                <p class="currentMatch__coefficient__count">{rate.oc_rate}</p>
                                            </button>
                                        )
                                    })}
                                </div>
                            ) : (
                                <div class="currentMatch__coefficients currentMatch__coefficients__grid">
                                    {event.game_oc.list[name].map((rate, key2) => {
                                        return (
                                            <button class={`currentMatch__coefficient ${tickets.includes(rate.oc_pointer) ? 'currentMatch__coefficient-active' : ''}`} key={key2} onClick={e => {
                                                dispatch(tickets.includes(rate.oc_pointer) ? removeTicket(rate.oc_pointer) : addTicket(rate.oc_pointer, event._));
                                            }}>
                                                <Rate value={rate.oc_rate} />
                                                <p class="currentMatch__coefficient__name">{rate.oc_name}</p>
                                                <p class="currentMatch__coefficient__count">{rate.oc_rate}</p>
                                            </button>
                                        )
                                    })}
                                </div>
                            )}
                        </>
                    )
                })}
            </div>
            {event.type === 'live' && (
                <div class="currentMatch__videos_noSlider">
                    <div>
                        <div class="currentMatch__video currentMatch__firstVideo">
                            <div class="currentMatch__video__live" style={{cursor: 'pointer'}} onClick={e => {
                                window.open(`https://www.google.com/search?q=${event.opp1.name_en} VS ${event.opp2.name_en} ${sport.name} live`, '_blank');
                            }}>
                                <div class="currentMatch__video__live__img">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                        <path d="M19.5036 13.5866C19.4907 13.5784 19.4771 13.5711 19.4633 13.5647L13.0674 10.2715C12.5552 9.97577 11.9455 9.97668 11.4346 10.2715C10.9237 10.5672 10.6184 11.0955 10.6184 11.686V18.3144C10.6184 18.9049 10.9237 19.4332 11.4346 19.7289C11.6951 19.879 11.9812 19.9523 12.2617 19.9523C12.5318 19.9523 12.7964 19.8845 13.0281 19.7499L19.5036 16.4138C20.0145 16.1181 20.3193 15.5898 20.3193 15.0002C20.3193 14.4106 20.0145 13.8824 19.5036 13.5866ZM12.4934 12.0843L18.1559 15.0002L12.4934 17.918V12.0843Z" fill="#F73E3E"></path>
                                        <path d="M24.2859 5.71948C23.9197 5.35327 23.3264 5.35327 22.9602 5.71948C22.594 6.08569 22.594 6.67896 22.9602 7.04516C25.0815 9.16641 26.2497 11.9917 26.2497 15.0002C26.2497 18.0086 25.0815 20.8339 22.9602 22.9552C22.594 23.3214 22.594 23.9147 22.9602 24.2809C23.1433 24.464 23.3832 24.5556 23.6231 24.5556C23.8629 24.5556 24.1028 24.464 24.2859 24.2809C26.7615 21.8062 28.1247 18.5103 28.1247 15.0002C28.1247 11.4901 26.7615 8.19416 24.2859 5.71948Z" fill="#F73E3E"></path>
                                        <path d="M21.5938 8.41259C21.2275 8.04639 20.6343 8.04639 20.2681 8.41259C19.9018 8.77884 19.9018 9.37209 20.2681 9.73826C23.1693 12.6396 23.1693 17.3601 20.2681 20.2613C19.9018 20.6276 19.9018 21.2208 20.2681 21.587C20.4512 21.7702 20.691 21.8617 20.9309 21.8617C21.1708 21.8617 21.4107 21.7702 21.5938 21.587C23.3507 19.8302 24.3183 17.491 24.3183 14.9998C24.3183 12.5087 23.3507 10.1695 21.5938 8.41259Z" fill="#F73E3E"></path>
                                        <path d="M7.03903 7.04516C7.40524 6.67896 7.40524 6.08569 7.03903 5.71948C6.67283 5.35327 6.07956 5.35327 5.71335 5.71948C3.23822 8.19416 1.875 11.491 1.875 15.0002C1.875 18.5094 3.23822 21.8062 5.71335 24.2809C5.89645 24.464 6.13632 24.5556 6.37619 24.5556C6.61606 24.5556 6.85593 24.464 7.03903 24.2809C7.40524 23.9147 7.40524 23.3214 7.03903 22.9552C2.65274 18.5689 2.65274 11.4315 7.03903 7.04516Z" fill="#F73E3E"></path>
                                        <path d="M9.73116 8.41259C9.365 8.04639 8.77166 8.04639 8.40549 8.41259C6.64813 10.1695 5.68042 12.5087 5.68042 14.9998C5.68042 17.491 6.64813 19.8302 8.40549 21.587C8.58858 21.7702 8.82849 21.8617 9.06833 21.8617C9.30816 21.8617 9.54808 21.7702 9.73116 21.587C10.0974 21.2208 10.0974 20.6276 9.73116 20.2613C8.32812 18.8588 7.55542 16.9902 7.55542 14.9998C7.55542 13.0095 8.32812 11.1408 9.73116 9.73826C10.0974 9.37209 10.0974 8.77884 9.73116 8.41259Z" fill="#F73E3E"></path>
                                    </svg>
                                </div>
                                <p class="currentMatch__video__live__text">Search Live</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}