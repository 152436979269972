import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/pages/sports';
import {getTopMatches, getTopLive, getUpcomingMatches, getLiveMatches, setLocation} from '../../../reducers/books/actions';
import {useParams} from 'react-router-dom';
import {getCountToShow} from '../../../utils/viewport';

import LoaderPage from '../loaderPage';
import NotFound from './notFound';
import Top from './top';
import Live from './live';
import Recent from './recent';

export default () => {
    const {sportId} = useParams(),
          {loaded, states, page, tickets} = useSelector(Selector),
          dispatch = useDispatch();

    const top_exist = page.top_matches.matches && page.top_matches.matches.length > 0,
          live_exist = page.live.matches && page.live.matches.length > 0,
          upcoming_exist = page.upcoming.matches && page.upcoming.matches.length > 0;



    useEffect(() => {
        if(page.location !== `/sports/${sportId}`) dispatch(setLocation(`/sports/${sportId}`));

        if(!loaded && page.location === `/sports/${sportId}`) {
            if(!states.top_matches) dispatch(getTopMatches(sportId));
            if(!states.live) dispatch(getLiveMatches(sportId, 1e3, 0));
            if(!states.upcoming) dispatch(getUpcomingMatches(sportId, 1e3, 0));
        } 
    }, [loaded, page.location, sportId]);

    if(!loaded)
        return <LoaderPage />

    if(!top_exist && !live_exist && !upcoming_exist)
        return <NotFound />

    return (
        <>
            <Top matches={page.top_matches} tickets={tickets} />
            <Live matches={page.live} tickets={tickets} />
            <Recent matches={page.upcoming} tickets={tickets} />
        </>
    )
}