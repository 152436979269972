import {useSelector} from 'react-redux';
import Selector from '../../../../selectors/pages/profile/bets';
import Numeric from '../../../components/numeric';

import Bet from './bet';
import MultiBet from './multi-bet';

export default () => {
    const {bets} = useSelector(Selector);

    return (
        <>
            <div class="subtitle container bet__maxWidth">
                <div class="subtitle__image">
                    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.7345 8.71638C6.05457 8.17135 7.53964 8.89139 8.47469 9.97144C9.14656 10.7487 9.98138 11.3684 10.9198 11.7865C12.0354 12.2853 13.2617 12.4846 14.4778 12.3649C15.694 12.2452 16.8579 11.8106 17.8548 11.1039C18.8517 10.3972 19.6472 9.44285 20.1629 8.33498C20.6785 7.22711 20.8965 6.00397 20.7953 4.78617C20.6853 3.4161 21.1703 1.91602 22.4204 1.33099L24.4705 0.365943C25.6223 -0.114955 26.9174 -0.122144 28.0745 0.345937C29.2316 0.814018 30.1573 1.71959 30.6508 2.86607L33.071 8.45636C32.979 8.64224 32.8923 8.83066 32.8109 9.02139L32.8059 9.03139L16.8551 45.8683L15.125 49.8035L0.344272 15.6017C-0.102517 14.4943 -0.114853 13.259 0.309729 12.1429C0.734311 11.0267 1.56452 10.1119 2.63439 9.58142L4.7345 8.71638ZM61.3474 18.7369C62.2725 17.6468 63.7625 16.9318 65.0826 17.4718L67.2977 18.3869C68.3948 18.9314 69.2462 19.8697 69.6819 21.0144C70.1175 22.1591 70.1054 23.426 69.6479 24.5622L51.2869 67.0644C50.7802 68.2415 49.8296 69.1711 48.6415 69.6514C47.4535 70.1317 46.1239 70.1238 44.9416 69.6296L22.7504 60.1741C21.5737 59.6683 20.6439 58.7189 20.1627 57.5319C19.6816 56.3449 19.6879 55.0161 20.1803 53.8337L22.5854 48.3684L38.5512 11.5015C39.057 10.3248 40.0064 9.39499 41.1934 8.91383C42.3804 8.43267 43.7092 8.43898 44.8916 8.93139L47.0617 9.94644C48.3117 10.5315 48.7968 12.0316 48.6718 13.4066C48.5784 14.4505 48.7129 15.5023 49.066 16.4891C49.4191 17.4759 49.9824 18.3742 50.7169 19.1219C51.4195 19.8345 52.2626 20.3932 53.1926 20.7626C54.1226 21.132 55.1193 21.3041 56.1193 21.2679C57.1194 21.2317 58.1009 20.988 59.0018 20.5523C59.9027 20.1166 60.7032 19.4984 61.3524 18.7369H61.3474Z" fill="#D38106"></path>
                    </svg>
                </div>
                <h2 class="subtitle__text">My Bets</h2>
            </div>
            {bets.length < 1 ? (
                <div class="container">
                    <div class="bets__empty__icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="268" height="218" viewBox="0 0 268 218" fill="none">
                            <path d="M136.355 200.355C131.511 205.2 125.723 208.997 119.35 211.511C112.977 214.025 106.155 215.203 99.3082 214.971C92.4612 214.74 85.7348 213.103 79.5465 210.164C73.3582 207.224 67.8401 203.044 63.3344 197.883C58.8288 192.722 55.4318 186.691 53.3543 180.162C51.2768 173.634 50.5631 166.748 51.2575 159.932C51.9518 153.117 54.0394 146.516 57.3908 140.541C60.7421 134.566 65.2857 129.343 70.7395 125.197" stroke="#2C5AD0" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M73.4981 54.1665C76.7039 52.843 80.3106 54.5915 82.5814 57.2143C84.2131 59.1017 86.2405 60.6066 88.5196 61.622C91.2288 62.8331 94.207 63.3172 97.1605 63.0265C100.114 62.7357 102.941 61.6803 105.362 59.9642C107.783 58.2481 109.715 55.9307 110.967 53.2404C112.219 50.5501 112.749 47.5798 112.503 44.6226C112.236 41.2955 113.414 37.6528 116.45 36.2321L121.428 33.8886C124.226 32.7208 127.371 32.7034 130.181 33.8401C132.991 34.9767 135.239 37.1758 136.438 39.9599L142.315 53.5351C142.092 53.9865 141.881 54.444 141.684 54.9072L141.672 54.9315L102.934 144.385L98.7322 153.941L62.8361 70.8867C61.751 68.1974 61.7211 65.1977 62.7522 62.4873C63.7833 59.7769 65.7996 57.5555 68.3978 56.2672L73.4981 54.1665ZM210.987 78.5C213.233 75.8529 216.852 74.1166 220.058 75.428L225.437 77.65C228.102 78.9723 230.169 81.2508 231.227 84.0306C232.285 86.8104 232.256 89.8869 231.145 92.646L186.554 195.857C185.323 198.715 183.015 200.973 180.129 202.139C177.244 203.305 174.015 203.286 171.144 202.086L117.251 179.124C114.393 177.896 112.135 175.591 110.967 172.708C109.798 169.826 109.813 166.599 111.009 163.728L116.85 150.456L155.624 60.9299C156.853 58.0724 159.158 55.8145 162.041 54.646C164.924 53.4776 168.151 53.4929 171.022 54.6887L176.293 57.1536C179.329 58.5742 180.506 62.217 180.203 65.5561C179.976 68.0912 180.303 70.6452 181.16 73.0415C182.018 75.4379 183.386 77.6193 185.17 79.435C186.876 81.1653 188.923 82.522 191.182 83.4191C193.441 84.3162 195.861 84.7341 198.29 84.6461C200.718 84.5582 203.102 83.9665 205.29 82.9084C207.478 81.8504 209.422 80.3492 210.999 78.5H210.987Z" fill="#737A87"></path>
                            <path d="M33.5 5V33.5M33.5 62V33.5M33.5 33.5C44.6299 33.5 50.8701 33.5 62 33.5H5" stroke="#2C5AD0" stroke-width="10" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M246 165V184M246 203V184M246 184C253.42 184 257.58 184 265 184H227" stroke="#2C5AD0" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M219 24V40M219 56V40M219 40C225.248 40 228.752 40 235 40H203" stroke="#2C5AD0" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </div>
                    <p class="search__empty__text">There's nothing here yet.</p>
                </div>
            ) : (
                <div class="container">
                    <div class="bets">
                        {bets.map((bet, key) => {
                            return bet.bets.length > 1 ? <MultiBet bet={bet} key={key} /> : <Bet bet={bet} key={key} />;
                        })}
                    </div>
                </div>
            )}
        </>
    )
}