import types from './types';

const InitialState = {
    loaded: false,
    error: null,
    balance: 0,
    stats: {
        winrate: 0,
        total_wins: 0,
        total_bets: 0,
        total_profit: 0
    },
    bets: [],
    buttons: {
        create_coupon: false
    }
}

export default (state = InitialState, {type, payload}) => {
    switch(type) {
        case types.stats:
            return {
                ...state,
                state: payload
            }
        case types.bet.update:
            return {
                ...state,
                bets: state.bets.map(bet => {
                    return bet.betId === payload.betId ? {
                        ...payload,
                        timestamp: payload.timestamp + Date.now()
                    } : bet
                })
            } 
        case types.toggleButton:
            return {
                ...state,
                buttons: {
                    ...state.buttons,
                    [payload]: !state.buttons[payload]
                }
            }
        case types.loaded:
            return {
                ...state,
                loaded: true,
                balance: payload.balance,
                bets: payload.bets.map(bet => {
                    return {
                        ...bet,
                        timestamp: bet.timestamp + Date.now()
                    }
                }),
                stats: payload.stats
            }
        case types.balance:
            return {
                ...state,
                balance: payload
            }
        case types.bet.add:
            return {
                ...state,
                stats: payload.stats,
                bets: [
                    {
                        ...payload.bet,
                        timestamp: payload.bet.timestamp + Date.now()
                    },
                    ...state.bets
                ]
            }
        case types.error:
            return {
                ...state,
                error: payload
            }
        default:
            return state;
    }
}