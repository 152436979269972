import {Component} from 'react';
import {connect} from 'react-redux';
import Selector from '../selectors/layout';
import {bindActionCreators} from 'redux';
import {getChampionships, getCountrys, getEvents, getSports} from '../reducers/books/actions';
import {getSession} from '../reducers/session/actions';
import Header from './components/header';
import HeaderMenu from './components/header_menu';
import Pages from './pages';
import Tickets from './components/tickets';
import {Routes, Route} from 'react-router-dom';
import WS from '../lib/websocket';
import Cookies from 'react-cookies';

class Layout extends Component {
    render() {
        const {loaded, sessionError} = this.props;

        if(sessionError)
            return (
            <div class="error-message">
                <h1 class="error-message__title">Whoops!</h1>
                <p class="error-message__description">{sessionError}</p>
            </div>
            )

        return (
            <>
                <div class="background"></div>

                {!loaded ? <Pages.Loader /> : (
                    <>
                        <Header />
                        <HeaderMenu />
                        <Routes>
                            <Route exact path='/' element={<Pages.Main />} />
                            <Route exact path='/live' element={<Pages.Live />} />
                            <Route exact path='/profile' element={<Pages.Profile />} />
                            <Route exact path='/event/:game_id' element={<Pages.Match />} />
                            <Route exact path='/sports/:sportId' element={<Pages.Sports />} />
                            <Route exact path='/search' element={<Pages.Search />} />
                            <Route exact path='/loader' element={<Pages.LoaderPage />} />
                        </Routes>

                        <Tickets />
                    </>
                )}
            </>
        )
    }

    componentDidMount() {
        const searchParams = new URLSearchParams(window.location.search),
              sessionId = searchParams.get('sessionId') || null;

        if(sessionId) {
            Cookies.save('sessionId', sessionId);
            window.localStorage.setItem('sessionId', sessionId);
        }

        const {loaded, states} = this.props; 

        if(!loaded) {
            if(!states.session) this.props.getSession();
            if(!states.championships) this.props.getChampionships();
            if(!states.countrys) this.props.getCountrys();
            if(!states.sports) this.props.getSports();
        }

        WS.init();
    }
}

export default connect(Selector, dispatch => bindActionCreators({
    getChampionships,
    getCountrys,
    getEvents,
    getSports,
    getSession
}, dispatch)) (Layout);