import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.session.bets,
    (bets) => {
        return {
            bets
        }
    }
)