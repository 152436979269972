import Main from './main';
import Sports from './sports';
import Live from './live';
import Match from './match';
import Profile from './profile';
import Search from './search';
import Loader from './loader';
import LoaderPage from './loaderPage';

export default {
    Main,
    Sports,
    Loader,
    Live,
    Match,
    Profile,
    Search,
    LoaderPage
}