import {parseString} from '../../../../utils/date';
import Numeric from '../../../components/numeric';
import SportIcon from '../../../components/sport_icon';
import {useNavigate} from 'react-router-dom';

export default ({bet}) => {
    const navigate = useNavigate();

    let bet_class = '';
    if(bet.status === 1) bet_class = 'bet__money__win';
    if(bet.status === 2) bet_class = 'bet__money__lose';

    return (
        <div class="bet__full">
            <div class="bet" id="bet1">
                <div class="bet__content">
                    <div class="bet__content__block">
                        <div class="bet__info">
                            <p class="bet__info__grey">ID {bet.betId}</p>
                            <p class="bet__info__white">{parseString(bet.timestamp)}</p>
                        </div>
                        <div class="bet__info">
                            <div class="bet__info__img">
                                <SportIcon sportId={bet.bets[0].sport.id} />
                            </div>
                            <p class="bet__info__white">{bet.bets[0].sport.name}</p>
                            <p class="bet__info__grey bet__info__bold" onClick={e => navigate(`/event/${bet.bets[0].event.id}`)}>{bet.bets[0].event.name}</p>
                        </div>
                    </div>
                    <div class="bet__content__block">
                        <div class="bet__info">
                            <p class="bet__info__grey">Pointer</p>
                            <p class="bet__info__white">
                                {bet.bets[0].pointer.oc_group_name} - {bet.bets[0].pointer.oc_name}
                            </p>
                        </div>
                    </div>
                    <div class="bet__content__block">
                        <div class="bet__info">
                            <p class="bet__info__grey">Amount</p>
                            <p class="bet__info__white">
                                <Numeric value={bet.amount} displayType="text" thousandSeparator={' '} decimalSeparator={','} decimalScale={2} />    
                            </p>
                        </div>
                        <div class="bet__info">
                            <p class="bet__info__grey">Rate</p>
                            <p class="bet__info__white">
                                <Numeric value={bet.bets[0].pointer.oc_rate} displayType="text" thousandSeparator={' '} prefix={'x'} decimalSeparator={'.'} decimalScale={3} />    
                            </p>
                        </div>
                    </div>
                    <div class="bet__content__block">
                        <div class="bet__info">
                            <p class="bet__info__grey">Potential</p>
                            <p class="bet__info__white">
                                <Numeric value={bet.bets[0].pointer.oc_rate * bet.amount} displayType="text" thousandSeparator={' '} decimalSeparator={'.'} decimalScale={2} />
                            </p>
                        </div>
                    </div>
                </div>
                <div class="bet__money__block bet__money__block__single">
                    <div class={`bet__money ${bet_class}`}>
                        {bet.status === 0 && <p>PENDING</p>}
                        {bet.status === 1 && <p>+<Numeric value={bet.result} displayType="text" thousandSeparator={' '} decimalSeparator={'.'} decimalScale={2} /></p>}
                        {bet.status === 2 && <p>-<Numeric value={bet.amount} displayType="text" thousandSeparator={' '} decimalSeparator={'.'} decimalScale={2} /></p>}
                        {bet.status === 3 && <p>CANCELED</p>}
                    </div>
                </div>
            </div>
        </div>
    )
}