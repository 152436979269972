import SportIcon from './sport_icon';

export default ({championships, onClickHandler, current_tag}) => {
    const championships_list = [];
    for(const key of Object.keys(championships))
        championships_list.push({
            championship: key,
            count: championships[key]
        });

    championships_list.sort((a,b) => {
        if(a.count < b.count) return 1;
        if(a.count > b.count) return -1;
        return 0;
    });

    if(Object.keys(championships).length == 0)
        return <></>;
        
    return (
        <div class="tags-draggable container">
            <div class="tags">
                {championships_list.map((c, key) => {
                    return (
                        <div class={`tags__item ${current_tag === c.championship ? 'tags__item-active' : ''}`} key={key} checked={current_tag === c.championship ? true : false} onClick={e => onClickHandler(current_tag === c.championship ? 'all' : c.championship)}>
                            <input type="checkbox" />
                            <label>
                                <p class="tags__item__name">{c.championship}</p>
                                <p class="tags__item__value">{c.count}</p>
                            </label>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}