import {createSelector} from 'reselect';
import props from '../../props';
import {matchesFormat} from '../../formats';

export default createSelector(
    props.books.page,
    props.books.championships,
    props.books.countrys,
    props.books.sports,
    props.books.tickets,
    (page, championships, countrys, sports, tickets) => {
        const isSearchLoaded = typeof page.search !== 'undefined';

        return {
            loaded: isSearchLoaded,
            states: {
                search: isSearchLoaded,
            },
            page: {
                location: page.location,
                search: isSearchLoaded ? matchesFormat(page.search, championships, countrys, sports) : []
            },
            tickets
        }
    }
)