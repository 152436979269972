import types from './types';
import booksTypes from '../books/types';
import API from '../../lib/api';
import WS from '../../lib/websocket';

export const createCoupon = (data, navigate) => async dispatch => {
    dispatch({
        type: types.toggleButton,
        payload: 'create_coupon'
    });

    const response = await API({
        path: '/bet',
        body: {
            ...data,
            sessionId: window.localStorage.getItem('sessionId')
        }
    });

    dispatch({
        type: types.toggleButton,
        payload: 'create_coupon'
    });
    
    if(response.success) {
        dispatch({
            type: types.bet.add,
            payload: {
                stats: response.stats,
                bet: response.bet
            }
        });

        dispatch({
            type: types.balance,
            payload: response.balance
        });

        dispatch({
            type: booksTypes.tickets.clear
        });

        document.dispatchEvent(new CustomEvent('tickets.close'));
        
        navigate('/profile');
    } else {
        window.alert(response.error)
    }
}

export const getSession = () => async dispatch => {
    const response = await API({
        path: '/getSession',
        body: {
            sessionId: window.localStorage.getItem('sessionId')
        }
    }); 

    if(response.success) {
        dispatch({
            type: types.loaded,
            payload: {
                balance: response.balance,
                stats: response.stats,
                bets: response.bets
            }
        });

        WS.authorize(response.userId);
    } else {
        dispatch({
            type: types.error,
            payload: response.error
        });
    }
}