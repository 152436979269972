const toggle = (state=null) => {
    const btn = document.querySelector('#openMenu'),
          btnMobile = document.querySelector('#OpenMenuMobile'),
          modal = document.querySelector('#headerMenu'),
          span = document.querySelector('#headerClose');

    let nextState = modal.style.display === 'block' ? false : true;
    if(state !== null) nextState = state;

    if(!nextState) {
        modal.style.display = "none";
        btn.style.display = "block";
        span.style.display = "none";
        document.body.style.overflow = "";
        document.body.style.height = "";
    } else {
        modal.style.display = "block";
        btn.style.display = "none";
        span.style.display = "block";
      
        document.body.style.height = 100 + "vh";
        document.body.style.overflow = "hidden";

        if (document.body.offsetHeight - 20 < modal.clientHeight) {
            document.body.style.height = modal.clientHeight + "px";
            modal.style.overflow = "auto";
            modal.style.height = 100 + "%";
        } else {
            modal.style.height = 100 + "%";
        }
    }
}

const setActive = () => {
    const links = document.querySelectorAll('.header__navigation__item');

    for(let i = 0; i < links.length; i++) {
        if(links[i].querySelector(`a[href="${window.location.pathname}"]`))
            links[i].classList.add('header__navigation__item_active');
        else
            links[i].classList.remove('header__navigation__item_active');
    }
}

export default {
    toggle,
    setActive
}