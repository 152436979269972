export default ({
    path,
    method="POST",
    body
}) => new Promise(async(resolve) => {
    fetch(`${window.location.origin}/api${path}`, {
        method,
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    }).then(res => res.json()).then(res => resolve(res)).catch(e => {
        console.log(e);

        return resolve({
            success: false,
            error: "Failed connect to server"
        });
    })
});