import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.books.sports,
    props.session.balance,
    (sports, balance) => {
        return {
            sports,
            balance
        }
    }
)