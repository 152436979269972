import {useState, useEffect} from 'react';
import Match from '../../components/match';
import {getCountToShow} from '../../../utils/viewport';
import Tags from '../../components/tags';
import {useDispatch} from 'react-redux';
import {updateMatches, getLiveMatches} from '../../../reducers/books/actions';
import TagsChampionships from '../../components/tags_championships';

export default ({matches:mathes_info, tickets}) => {
    const count_to_show = getCountToShow(),
          dispatch = useDispatch(),
          [current_tag, setCurrentTag] = useState('all'),
          matches = mathes_info.matches ? mathes_info.matches.filter(m => current_tag === 'all' ? true : (m.championship === current_tag)) : mathes_info.matches,
          matches_all = mathes_info.matches ? mathes_info.matches : [];
        //   matches = mathes_info.matches ? mathes_info.matches.filter((_, key) => key < count_to_show) : mathes_info.matches;

    const championships = {};
    for(const m of matches_all) {
        if(typeof championships[m.championship] === 'undefined')
            championships[m.championship] = 0;

        championships[m.championship]++;
    }
    
    if(mathes_info.matches && mathes_info.matches.length === 0)
        return <></>;

    return (
        <>
            <div class="subtitle container">
                <div class="subtitle__image">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                        <path d="M32.5059 22.6443C32.4845 22.6306 32.4617 22.6184 32.4388 22.6077L21.7789 17.1191C20.9252 16.6262 19.9091 16.6277 19.0575 17.1191C18.2062 17.6119 17.6973 18.4924 17.6973 19.4766V30.524C17.6973 31.5081 18.2062 32.3886 19.0575 32.8815C19.4917 33.1316 19.9685 33.2537 20.4362 33.2537C20.8863 33.2537 21.3273 33.1408 21.7134 32.9165L32.5059 27.3562C33.3574 26.8634 33.8655 25.9829 33.8655 25.0002C33.8655 24.0176 33.3574 23.1372 32.5059 22.6443ZM20.8223 20.1404L30.2598 25.0002L20.8223 29.8633V20.1404Z" fill="#F73E3E"></path>
                        <path d="M40.4764 9.53247C39.866 8.92212 38.8772 8.92212 38.2668 9.53247C37.6565 10.1428 37.6565 11.1316 38.2668 11.7419C41.8023 15.2774 43.7493 19.9862 43.7493 25.0003C43.7493 30.0143 41.8023 34.7232 38.2668 38.2586C37.6565 38.869 37.6565 39.8578 38.2668 40.4682C38.5721 40.7733 38.9718 40.926 39.3717 40.926C39.7714 40.926 40.1711 40.7733 40.4764 40.4682C44.6023 36.3437 46.8743 30.8506 46.8743 25.0003C46.8743 19.1501 44.6023 13.6569 40.4764 9.53247Z" fill="#F73E3E"></path>
                        <path d="M35.9895 14.0212C35.3791 13.4109 34.3904 13.4109 33.7801 14.0212C33.1697 14.6316 33.1697 15.6204 33.7801 16.2307C38.6155 21.0662 38.6155 28.9337 33.7801 33.7691C33.1697 34.3796 33.1697 35.3683 33.7801 35.9786C34.0852 36.2839 34.4849 36.4364 34.8848 36.4364C35.2845 36.4364 35.6844 36.2839 35.9895 35.9786C38.9177 33.0505 40.5305 29.1519 40.5305 25C40.5305 20.848 38.9177 16.9494 35.9895 14.0212Z" fill="#F73E3E"></path>
                        <path d="M11.7315 11.7419C12.3418 11.1316 12.3418 10.1428 11.7315 9.53247C11.1211 8.92212 10.1324 8.92212 9.52201 9.53247C5.39678 13.6569 3.12476 19.1517 3.12476 25.0003C3.12476 30.849 5.39678 36.3437 9.52201 40.4682C9.82717 40.7733 10.227 40.926 10.6267 40.926C11.0265 40.926 11.4263 40.7733 11.7315 40.4682C12.3418 39.8578 12.3418 38.869 11.7315 38.2586C4.42099 30.9482 4.42099 19.0525 11.7315 11.7419Z" fill="#F73E3E"></path>
                        <path d="M16.2183 14.0212C15.608 13.4109 14.6191 13.4109 14.0088 14.0212C11.0799 16.9494 9.46704 20.848 9.46704 25C9.46704 29.1519 11.0799 33.0505 14.0088 35.9786C14.314 36.2839 14.7138 36.4364 15.1136 36.4364C15.5133 36.4364 15.9131 36.2839 16.2183 35.9786C16.8287 35.3683 16.8287 34.3796 16.2183 33.7691C13.8799 31.4315 12.592 28.3172 12.592 25C12.592 21.6828 13.8799 18.5683 16.2183 16.2307C16.8287 15.6204 16.8287 14.6316 16.2183 14.0212Z" fill="#F73E3E"></path>
                    </svg>
                </div>
                <h2 class="subtitle__text">Live</h2>
            </div>
            {!mathes_info.matches ? (
                <div class="loader__fullBlock" style={{minHeight: '100px'}}>
                    <div class="loader__fullBlock__elem">
                        <span class="loader"></span>
                    </div>
                </div>
            ) : (
                <>
                    <TagsChampionships
                        championships={championships}
                        current_tag={current_tag}
                        onClickHandler={tab => {
                            console.log('Tab clicked');
                            setCurrentTag(tab);
                        }} />
                    <div class="container">
                        <div 
                            className="match__slider">
                            {matches.map((match, key) => <Match match={match} key={key} isLive={true} tickets={tickets} />)}
                        </div>
                    </div>
                </>
            )}
        </>
    )
}