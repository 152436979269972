import {useState, useEffect} from 'react';
import {parseString, parseDate} from '../../../utils/date';

export default ({game_start}) => {
    const [state, setState] = useState(parseDate(game_start));

    useEffect(() => {
        window.timer_timeout = setTimeout(() => {
            setState(parseDate(game_start));
        }, 1e3);

        return () => clearTimeout(window.timer_timeout);
    }, [state]);

    return (
        <div class="currentMatch__time">
            <div class="currentMatch__time__block">
                <div class="currentMatch__time__block__item">
                    <div class="currentMatch__time__team">
                        <p>{state.days[0]}</p>
                    </div>
                    <div class="currentMatch__time__team">
                        <p>{state.days[1]}</p>
                    </div>
                </div>
                <div class="currentMatch__time__block__item">
                    <div class="currentMatch__time__team">
                        <p>{state.hours[0]}</p>
                    </div>
                    <div class="currentMatch__time__team">
                        <p>{state.hours[1]}</p>
                    </div>
                </div>
                <div class="currentMatch__time__block__item">
                    <div class="currentMatch__time__team">
                        <p>{state.minutes[0]}</p>
                    </div>
                    <div class="currentMatch__time__team">
                        <p>{state.minutes[1]}</p>
                    </div>
                </div>
                <div class="currentMatch__time__block__item">
                    <div class="currentMatch__time__team">
                        <p>{state.seconds[0]}</p>
                    </div>
                    <div class="currentMatch__time__team">
                        <p>{state.seconds[1]}</p>
                    </div>
                </div>
            </div>
            <p class="currentMatch__time__fullTime">{parseString(game_start)}</p>
        </div>
    )
}