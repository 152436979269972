import {Component, createRef} from 'react';

class Rate extends Component {
    constructor() {
        super();

        this.ref = createRef();
    }

    render() {
        return (
            <>
                <div className="blink" ref={this.ref}></div>
                {this.props.children}
            </>
        )
    }

    componentDidUpdate(prevProps) {
        if(this.ref.current) {
            if(this.props.value > prevProps.value) {
                this.ref.current.classList.add('blink-win');
                console.log('blink-win')
            } else if(this.props.value < prevProps.value) {
                this.ref.current.classList.add('blink-lose');
                console.log('blink-lose');
            }
        }
    }
}

export default Rate;