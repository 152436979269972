import {createSelector} from 'reselect';
import props from '../../props';
import {formatRates} from '../../formats';

export default createSelector(
    props.books.event,
    props.books.sports,
    props.books.championships,
    props.books.tickets,
    (event, sports, championships, tickets) => {
        const sport = (event && event.found) ? sports.filter(s => s.sportId == event.sportId)[0] || false : false,
              championship = (event && event.found) ? championships.filter(c => c.sportId == event.sportId && c.championshipId == event.championshipId)[0] || false : false;

        return {
            event: (event && event.found) ? {
                ...event,
                game_oc: {
                    ...event.game_oc,
                    list: formatRates(event.game_oc.list)
                },
                _: event
            } : event,
            sport,
            championship,
            tickets
        }
    }
)