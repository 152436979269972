import Numeric from '../numeric';
import {useState} from 'react';
import SportIcon from '../sport_icon';
import {useDispatch} from 'react-redux';
import {removeTicket} from '../../../reducers/books/actions';
import {createCoupon} from '../../../reducers/session/actions';
import {useNavigate} from 'react-router-dom';

export default ({opened, setOpened, tickets, button}) => {
    const [data, setData] = useState({
        amount: ''
    }) 

    const changeAmount = amount => {
        setData({
            ...data,
            amount
        });

        setTimeout(() => {
            const input = document.querySelector('.tickets__main__input');
            input.value = `$${amount}`;
        },1e1);
    }

    const not_combined = tickets.filter(t => !t.combined),
          combined = tickets.filter(t => t.combined),
          dispatch = useDispatch(),
          navigate = useNavigate();

    function calculateRate() {
        let rate = 1;
        for(const i in tickets) rate *= tickets[i].counter.oc_rate;
        return rate;
    }

    function calculatePotentialGain () {
        return parseFloat((data.amount*calculateRate()).toFixed(2));
    }

    return (
        <div className={`tickets ${opened ? 'tickets_opened' : ''}`} id="tickets">
            <div class="tickets__header"  onClick={e => setOpened(!opened)} style={{cursor: 'pointer'}}>
                <div class="tickets__header__block">
                    <div class="tickets__header__block__img">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                            <g clip-path="url(#clip0_180_2)">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.02897 3.7354C2.59468 3.50183 3.23111 3.8104 3.63182 4.27325C3.91975 4.60634 4.27751 4.87192 4.67968 5.05111C5.15776 5.26484 5.6833 5.35027 6.20447 5.29897C6.72563 5.24766 7.22443 5.0614 7.65166 4.75855C8.07889 4.45571 8.41981 4.04673 8.64079 3.57195C8.86177 3.09717 8.95519 2.573 8.91182 2.05111C8.86468 1.46397 9.07254 0.821111 9.60825 0.570396L10.4868 0.156825C10.9804 -0.0492638 11.5354 -0.0523446 12.0313 0.148251C12.5272 0.348847 12.9239 0.736929 13.1354 1.22825L14.1725 3.62397C14.1331 3.70363 14.096 3.78437 14.0611 3.86611L14.059 3.8704L7.22325 19.6568L6.48182 21.3433L0.147538 6.68611C-0.0439334 6.21152 -0.0492203 5.68214 0.132734 5.20381C0.314688 4.72549 0.670475 4.33346 1.12897 4.10611L2.02897 3.7354ZM26.2904 8.02968C26.6868 7.56254 27.3254 7.25611 27.8911 7.48754L28.8404 7.87968C29.3106 8.11304 29.6754 8.51514 29.8621 9.00571C30.0488 9.49628 30.0436 10.0392 29.8475 10.5261L21.979 28.7404C21.7618 29.2448 21.3544 29.6432 20.8453 29.849C20.3362 30.0549 19.7664 30.0515 19.2597 29.8397L9.74968 25.7875C9.2454 25.5708 8.84693 25.1639 8.64073 24.6553C8.43453 24.1466 8.43723 23.5771 8.64825 23.0704L9.67896 20.7283L16.5211 4.92897C16.7379 4.42469 17.1447 4.02622 17.6534 3.82002C18.1621 3.61381 18.7315 3.61652 19.2382 3.82754L20.1682 4.26254C20.704 4.51325 20.9118 5.15611 20.8582 5.7454C20.8182 6.19277 20.8759 6.6435 21.0272 7.06639C21.1785 7.48929 21.4199 7.87427 21.7347 8.19468C22.0358 8.50004 22.3971 8.73948 22.7956 8.89779C23.1942 9.05611 23.6213 9.12985 24.0499 9.11434C24.4785 9.09882 24.8991 8.99439 25.2852 8.80767C25.6713 8.62095 26.0143 8.35602 26.2925 8.02968H26.2904Z" fill="white"></path>
                            </g>
                            <defs>
                                <clipPath id="clip0_180_2">
                                    <rect width="30" height="30" fill="white"></rect>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <p class="tickets__header__block__text">My ticket</p>
                    {tickets.length > 0 && (
                        <>
                            {combined.length > 0 ? (
                                <div class="tickets__header__block__elipse" style={{background: 'unset'}}>
                                    <svg class="bt2320" data-cy="ic-betslip-error" width="16" height="16" viewBox="0 0 16 16" fill="F5A623" xmlns="http://www.w3.org/2000/svg" style={{fill: 'currentcolor', color: 'rgb(245, 166, 35)', width: 'auto', height: '23px'}}><path d="M9 4C9 3.44772 8.55228 3 8 3C7.44772 3 7 3.44772 7 4V9C7 9.55228 7.44772 10 8 10C8.55228 10 9 9.55228 9 9V4Z"></path><path d="M8 11C8.55228 11 9 11.4477 9 12C9 12.5523 8.55228 13 8 13C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11Z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"></path></svg>
                                </div>
                            ) : (
                                <div class="tickets__header__block__elipse">
                                    <p>{tickets.length}</p>
                                </div>
                            )}
                        </>
                    )}
                </div>
                <button class="tickets__header__block__btn" id="openTickets" style={{
                    transform: `rotate(${opened ? 0 : 180}deg)`
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="11" viewBox="0 0 13 11" fill="none">
                        <path d="M7.36602 10.5C6.98112 11.1667 6.01887 11.1667 5.63397 10.5L0.870832 2.25C0.485932 1.58333 0.967058 0.749999 1.73686 0.749999L11.2631 0.75C12.0329 0.75 12.5141 1.58333 12.1292 2.25L7.36602 10.5Z" fill="white"></path>
                    </svg>
                </button>
            </div>
            <div className={`tickets_closed ${opened ? 'ticket__opened' : ''}`} id="tickets_opened">
                <div class="tickets__main">
                    {tickets.length === 0 ? (
                        <div class="tickets__main__cupons__clear">
                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" class="bt1347" data-editor-id="emptyBetSlipIcon">
                                <g clip-path="url(#clip0_19728_23370)">
                                    <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z" fill="#2283F6"></path>
                                    <path opacity="0.7" fill-rule="evenodd" clip-rule="evenodd" d="M39.1395 42.6234C35.0096 45.9848 29.7401 48 24 48C18.3243 48 13.1088 46.0298 9 42.7361V14H12C12 15.6569 13.3431 17 15 17C16.6569 17 18 15.6569 18 14H21C21 15.6569 22.3431 17 24 17C25.6569 17 27 15.6569 27 14H30C30 15.6569 31.3431 17 33 17C34.6569 17 36 15.6569 36 14H39.1395V42.6234ZM16.5 24C15.6716 24 15 24.6716 15 25.5C15 26.3284 15.6716 27 16.5 27H31.5C32.3284 27 33 26.3284 33 25.5C33 24.6716 32.3284 24 31.5 24H16.5ZM16.5 31C15.6716 31 15 31.6716 15 32.5C15 33.3284 15.6716 34 16.5 34H27.5C28.3284 34 29 33.3284 29 32.5C29 31.6716 28.3284 31 27.5 31H16.5Z" fill="#2283F6"></path>
                                </g>
                                <defs>
                                    <clipPath id="clip0_19728_23370">
                                        <rect width="48" height="48" fill="white"></rect>
                                    </clipPath>
                                </defs>
                            </svg>
                            <div class="tickets__main__cupons__clear__descr">
                                <p class="tickets__main__cupons__clear__descr__title">
                                    Place your bets
                                </p>
                                <p class="tickets__main__cupons__clear__descr__text">
                                    Your selection will appear in this area
                                </p>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div class="tickets__main__cupons">
                                {not_combined.map((ticket, key) => {
                                    return (
                                        <div class="tickets__main__cupon" key={key}>
                                            <div class="tickets__main__cupon__delete" onClick={e => {
                                                dispatch(removeTicket(ticket.oc_counter))
                                            }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                                    <path d="M15.2132 1.58572L8.14209 8.65678M1.07102 15.7279L8.14209 8.65678M8.14209 8.65678C10.9035 11.4182 12.4517 12.9664 15.2132 15.7279L1.07102 1.58572" stroke="#B1B9C4" stroke-width="2"></path>
                                                </svg>
                                            </div>
                                            <div class="tickets__main__cupon__content">
                                                <div class="tickets__main__cupon__title">
                                                    <div class="tickets__main__cupon__img">
                                                        <SportIcon sportId={ticket.event.sportId} />
                                                    </div>
                                                    <a class="tickets__main__cupon__name">
                                                        {ticket.sport.name}
                                                    </a>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="8" viewBox="0 0 5 8" fill="none" style={{marginLeft: '5px', marginRight: '4px'}}><path d="M1.71 7.28997L4.3 4.69997C4.3927 4.60746 4.46625 4.49757 4.51643 4.3766C4.56661 4.25562 4.59244 4.12594 4.59244 3.99497C4.59244 3.864 4.56661 3.73432 4.51643 3.61335C4.46625 3.49237 4.3927 3.38249 4.3 3.28997L1.71 0.699971C1.08 0.0799712 0 0.519971 0 1.40997V6.57997C0 7.47997 1.08 7.91997 1.71 7.28997Z" fill="#9FA4AC"></path></svg>
                                                    <a class="tickets__main__cupon__name">
                                                        {ticket.championship.name}
                                                    </a>
                                                </div>
                                                <p class="tickets__main__cupon__text">
                                                    {ticket.event.opp1.name_en} - {ticket.event.opp2.name_en}
                                                    {ticket.counter.oc_group_name}
                                                </p>
                                                <p class="tickets__main__cupon__type">{ticket.counter.oc_name}</p>
                                            </div>
                                            <div class="tickets__main__cupon__count">
                                                <p>x{ticket.counter.oc_rate}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            {combined.length > 0 && (
                                <>
                                    <div class="tickets__main__cupons__alert__border">
                                        {combined.map((ticket, key) => {
                                            return (
                                                <div class="tickets__main__cupon" key={key}>
                                                    <div class="tickets__main__cupon__delete" onClick={e => {
                                                        dispatch(removeTicket(ticket.oc_counter))
                                                    }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                                            <path d="M15.2132 1.58572L8.14209 8.65678M1.07102 15.7279L8.14209 8.65678M8.14209 8.65678C10.9035 11.4182 12.4517 12.9664 15.2132 15.7279L1.07102 1.58572" stroke="#B1B9C4" stroke-width="2"></path>
                                                        </svg>
                                                    </div>
                                                    <div class="tickets__main__cupon__content">
                                                        <div class="tickets__main__cupon__title">
                                                            <div class="tickets__main__cupon__img">
                                                                <SportIcon sportId={ticket.event.sportId} />
                                                            </div>
                                                            <a class="tickets__main__cupon__name">
                                                                {ticket.sport.name}
                                                            </a>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="5" height="8" viewBox="0 0 5 8" fill="none" style={{marginLeft: '5px', marginRight: '4px'}}><path d="M1.71 7.28997L4.3 4.69997C4.3927 4.60746 4.46625 4.49757 4.51643 4.3766C4.56661 4.25562 4.59244 4.12594 4.59244 3.99497C4.59244 3.864 4.56661 3.73432 4.51643 3.61335C4.46625 3.49237 4.3927 3.38249 4.3 3.28997L1.71 0.699971C1.08 0.0799712 0 0.519971 0 1.40997V6.57997C0 7.47997 1.08 7.91997 1.71 7.28997Z" fill="#9FA4AC"></path></svg>
                                                            <a class="tickets__main__cupon__name">
                                                                {ticket.championship.name}
                                                            </a>
                                                        </div>
                                                        <p class="tickets__main__cupon__text">
                                                            {ticket.event.opp1.name_en} - {ticket.event.opp2.name_en}
                                                            {ticket.counter.oc_group_name}
                                                        </p>
                                                        <p class="tickets__main__cupon__type">{ticket.counter.oc_name}</p>
                                                    </div>
                                                    <div class="tickets__main__cupon__count">
                                                        <p>x{ticket.counter.oc_rate}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div class="tickets__cupon__alert">
                                        <svg class="bt2320" data-cy="ic-betslip-error" width="16" height="16" viewBox="0 0 16 16" fill="F5A623" xmlns="http://www.w3.org/2000/svg"  style={{fill: 'currentcolor', color: 'rgb(245, 166, 35)', width: 'auto', height: '16px'}}>
                                            <path d="M9 4C9 3.44772 8.55228 3 8 3C7.44772 3 7 3.44772 7 4V9C7 9.55228 7.44772 10 8 10C8.55228 10 9 9.55228 9 9V4Z"></path>
                                            <path d="M8 11C8.55228 11 9 11.4477 9 12C9 12.5523 8.55228 13 8 13C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11Z"></path>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"></path>
                                        </svg>
                                        <p class="tickets__cupon__alert__text">Bets cannot be combined</p>
                                    </div>
                                </>
                            )}
                            <Numeric 
                                className="tickets__main__input"
                                inputMode={false}
                                decimalScale={2} 
                                decimalSeparator={','} 
                                thousandSeparator=" "
                                defaultValue={data.amount}
                                onChange={e => {
                                    console.log(e);

                                    setData({
                                        ...data,
                                        amount: parseFloat(e.target.value.replace(/ /g, '').replace(',', '.').replace('$', ''))
                                    });
                                }}
                                required  />
                            <div class="tickets__main__input__buttons">
                                <button className={`tickets__main__input__button ${data.amount === 5 ? 'tickets__main__input__button_active' : ''}`} onClick={e => changeAmount(5)}>$5</button>
                                <button className={`tickets__main__input__button ${data.amount === 10 ? 'tickets__main__input__button_active' : ''}`} onClick={e => changeAmount(10)}>$10</button>
                                <button className={`tickets__main__input__button ${data.amount === 20 ? 'tickets__main__input__button_active' : ''}`} onClick={e => changeAmount(20)}>$20</button>
                                <button className={`tickets__main__input__button ${data.amount === 50 ? 'tickets__main__input__button_active' : ''}`} onClick={e => changeAmount(50)}>$50</button>
                                <button className={`tickets__main__input__button ${data.amount === 100 ? 'tickets__main__input__button_active' : ''}`} onClick={e => changeAmount(100)}>$100</button>
                            </div>
                            <div class="tickets__main__info">
                                <div class="tickets__main__info__elem">
                                    <p>Final coefficient</p>
                                    <p class="tickets__main__info__right">
                                        <Numeric value={calculateRate()} displayType="text" thousandSeparator={''} decimalSeparator={'.'} decimalScale={3} prefix={'x'} />
                                    </p>
                                </div>
                                <div class="tickets__main__info__elem">
                                    <p>Final bet</p>
                                    <p class="tickets__main__info__right">
                                        <Numeric value={data.amount || 0} displayType="text" thousandSeparator={' '} decimalSeparator={','} decimalScale={3} />
                                    </p>
                                </div>
                            </div>
                            <div class="tickets__main__prize">
                                <p>Potential gain</p>
                                <p class="tickets__main__prize__right">
                                    <Numeric value={calculatePotentialGain()} displayType="text" thousandSeparator={' '} decimalSeparator={','} decimalScale={3} />
                                </p>
                            </div>
                            <button class="tickets__main__makeBet" style={{
                                opacity: (combined.length > 0 || data.amount <= 0 || button) ? 0.7 : 1,
                                cursor: (combined.length > 0 || data.amount <= 0 || button) ? 'no-drop' : 'pointer'
                            }} onClick={e => {
                                if(combined.length > 0 || data.amount <= 0 || button) return;

                                dispatch(createCoupon({
                                    bets: tickets.map(ticket => {
                                        return {
                                            eventId: ticket.event.game_id,
                                            sportId: ticket.event.sportId,
                                            championshipId: ticket.event.championshipId,
                                            counter: ticket.oc_counter
                                        }
                                    }),
                                    amount: data.amount
                                }, navigate));
                            }}>{button ? 'PLEASE WAIT' : 'CREATE A COUPON'}</button>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}