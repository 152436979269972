import {Link, useNavigate} from 'react-router-dom';
import {updateVisibleList} from '../../lib/websocket';
import {useEffect} from 'react';
import Rate from './rate';
import SportIcon from './sport_icon';
import {useDispatch} from 'react-redux';
import {addTicket, removeTicket} from '../../reducers/books/actions';

export default ({match, tickets, key}) => {
    const navigate = useNavigate(),
          dispatch = useDispatch();

    useEffect(() => {
        updateVisibleList(match.game_id);
    }, [match.game_id]);

    return (
        <div key={key}>
            <div class="live__item">
                <div class="live__item__header">
                    <div class="live__item__header__tournament">
                        <div class="live__header__tournament__image">
                            <SportIcon sportId={match.sportId} />
                        </div>
                        <p class="live__header__tournament__name">
                            <span style={{cursor: 'pointer'}} onClick={e => navigate(`/sports/${match.sportId}`)}>{match.sport.name}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="5" height="8" viewBox="0 0 5 8" fill="none" style={{
                                marginLeft: '5px',
                                marginRight: '4px'
                            }}>
                                <path d="M1.71 7.28997L4.3 4.69997C4.3927 4.60746 4.46625 4.49757 4.51643 4.3766C4.56661 4.25562 4.59244 4.12594 4.59244 3.99497C4.59244 3.864 4.56661 3.73432 4.51643 3.61335C4.46625 3.49237 4.3927 3.38249 4.3 3.28997L1.71 0.699971C1.08 0.0799712 0 0.519971 0 1.40997V6.57997C0 7.47997 1.08 7.91997 1.71 7.28997Z" fill="#9FA4AC"/>
                            </svg>
                            {match.championship}
                        </p>
                    </div>
                    <div class="live__header__info">
                        <p class="live__header__info__status">{match.score.period_name || 'Live'}</p>
                        <div class="live__header__info__icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                <path d="M19.5038 13.5864C19.491 13.5782 19.4773 13.5709 19.4636 13.5644L13.0677 10.2713C12.5554 9.97552 11.9457 9.97644 11.4348 10.2713C10.924 10.5669 10.6187 11.0953 10.6187 11.6858V18.3142C10.6187 18.9047 10.924 19.4329 11.4348 19.7287C11.6953 19.8788 11.9814 19.952 12.262 19.952C12.5321 19.952 12.7967 19.8843 13.0283 19.7497L19.5038 16.4135C20.0147 16.1179 20.3196 15.5895 20.3196 14.9999C20.3196 14.4104 20.0147 13.8821 19.5038 13.5864ZM12.4937 12.084L18.1562 14.9999L12.4937 17.9178V12.084Z" fill="#F73E3E"></path>
                                <path d="M24.2859 5.71948C23.9197 5.35327 23.3264 5.35327 22.9602 5.71948C22.594 6.08569 22.594 6.67895 22.9602 7.04516C25.0815 9.16641 26.2497 11.9917 26.2497 15.0002C26.2497 18.0086 25.0815 20.8339 22.9602 22.9552C22.594 23.3214 22.594 23.9147 22.9602 24.2809C23.1433 24.464 23.3832 24.5556 23.6231 24.5556C23.8629 24.5556 24.1028 24.464 24.2859 24.2809C26.7615 21.8062 28.1247 18.5103 28.1247 15.0002C28.1247 11.4901 26.7615 8.19415 24.2859 5.71948Z" fill="#F73E3E"></path>
                                <path d="M21.594 8.41283C21.2278 8.04663 20.6345 8.04663 20.2683 8.41283C19.9021 8.77908 19.9021 9.37233 20.2683 9.7385C23.1696 12.6398 23.1696 17.3603 20.2683 20.2616C19.9021 20.6278 19.9021 21.2211 20.2683 21.5872C20.4514 21.7704 20.6913 21.8619 20.9312 21.8619C21.171 21.8619 21.4109 21.7704 21.594 21.5872C23.3509 19.8304 24.3186 17.4912 24.3186 15.0001C24.3186 12.5089 23.3509 10.1697 21.594 8.41283Z" fill="#F73E3E"></path>
                                <path d="M7.03903 7.04516C7.40524 6.67895 7.40524 6.08569 7.03903 5.71948C6.67282 5.35327 6.07956 5.35327 5.71335 5.71948C3.23822 8.19415 1.875 11.491 1.875 15.0002C1.875 18.5094 3.23822 21.8062 5.71335 24.2809C5.89645 24.464 6.13632 24.5556 6.37619 24.5556C6.61606 24.5556 6.85592 24.464 7.03903 24.2809C7.40524 23.9147 7.40524 23.3214 7.03903 22.9552C2.65274 18.5689 2.65274 11.4315 7.03903 7.04516Z" fill="#F73E3E"></path>
                                <path d="M9.73092 8.41283C9.36475 8.04663 8.77142 8.04663 8.40525 8.41283C6.64788 10.1697 5.68018 12.5089 5.68018 15.0001C5.68018 17.4912 6.64788 19.8304 8.40525 21.5872C8.58833 21.7704 8.82825 21.8619 9.06808 21.8619C9.30792 21.8619 9.54783 21.7704 9.73092 21.5872C10.0972 21.2211 10.0972 20.6278 9.73092 20.2616C8.32788 18.859 7.55518 16.9904 7.55518 15.0001C7.55518 13.0097 8.32788 11.1411 9.73092 9.7385C10.0972 9.37233 10.0972 8.77908 9.73092 8.41283Z" fill="#F73E3E"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="live__item__center">
                    <img class="live__item__team__img" src={`https://cdn.incub.space/v1/opp/icon/${match.opp1.icon.split('.png')[0]}.png`} alt="" />
                    <div class="live__item__score">
                        <div class="live__item__score__elem">
                            <p>{match.score.full.split(':')[0]}</p>
                        </div>
                        <p>:</p>
                        <div class="live__item__score__elem">
                            <p>{match.score.full.split(':')[1]}</p>
                        </div>
                    </div>
                    <img class="live__item__team__img" src={`https://cdn.incub.space/v1/opp/icon/${match.opp2.icon.split('.png')[0]}.png`} alt="" />
                </div>
                <div class="live__item__teams">
                    <p class="live__item__team__name">{match.opp1.name_en}</p>
                    <p class="live__item__team__name">{match.opp2.name_en}</p>
                </div>
                <div class="live__item__odds" style={{gap: '4px'}}>
                        {match.rates.length === 3 && (
                            <div class="live__item__odds__coefficient">
                                {match.rates.map((rate, key) => {
                                    return (
                                        <button className={`live__item__team  live__item__odds__block ${tickets.includes(rate.oc_pointer) ? 'live__item__odds__block-active' : ''}`} key={key} onClick={e => {
                                            dispatch(tickets.includes(rate.oc_pointer) ? removeTicket(rate.oc_pointer) : addTicket(rate.oc_pointer, match._));
                                        }}>
                                            <Rate value={rate.oc_rate} />
                                            <p class="live__item__draft">{rate.oc_name}</p>
                                            <p class="live__item__coefficient">{rate.oc_rate}</p>
                                        </button>
                                    )
                                })}
                            </div>
                        )}
                        
                        {match.rates.length === 2 && (
                            <div class="live__item__odds__coefficient" style={{
                                display: 'block',
                                width: '101%'
                            }}>
                                <button className={`live__item__team  live__item__odds__block ${tickets.includes(match.rates[0].oc_pointer) ? 'live__item__odds__block-active' : ''}`} style={{opacity: 1, cursor: 'pointer', width: 'calc(49%)', float: 'left'}} onClick={e => {
                                    dispatch(tickets.includes(match.rates[0].oc_pointer) ? removeTicket(match.rates[0].oc_pointer) : addTicket(match.rates[0].oc_pointer, match._));
                                }}>
                                    <Rate value={match.rates[0].oc_rate} />
                                    <p class="live__item__draft">{match.rates[0].oc_name}</p>
                                    <p class="live__item__coefficient">{match.rates[0].oc_rate}</p>
                                </button>

                                <button className={`live__item__team  live__item__odds__block ${tickets.includes(match.rates[1].oc_pointer) ? 'live__item__odds__block-active' : ''}`} style={{opacity: 1, cursor: 'pointer', width: 'calc(49%)', float: 'left', marginLeft: '1%'}}  onClick={e => {
                                    dispatch(tickets.includes(match.rates[1].oc_pointer) ? removeTicket(match.rates[1].oc_pointer) : addTicket(match.rates[1].oc_pointer, match._));
                                }}>
                                    <Rate value={match.rates[1].oc_rate} />
                                    <p class="live__item__draft">{match.rates[1].oc_name}</p>
                                    <p class="live__item__coefficient">{match.rates[1].oc_rate}</p>
                                </button>
                            </div>
                        )}

                        {match.rates.length === 0 && (
                            <div class="live__item__odds__coefficient" style={{
                                display: 'block',
                                width: '101%'
                            }}>
                                <Link to={`/event/${match.game_id}`} class="live__item__team live__item__odds__block live__item__team__linkToAll" style={{opacity: 1, cursor: 'pointer', width: 'calc(100%)'}}>
                                    <p class="live__item__coefficient">See all markets</p>
                                </Link>
                            </div>
                        )}
                    <button class="live__item__odds__stats" onClick={e => navigate(`/event/${match.game_id}`)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                            <path d="M11.25 21.25H8.75V12.5H11.25V21.25ZM16.25 21.25H13.75V8.75H16.25V21.25ZM21.25 21.25H18.75V16.25H21.25V21.25ZM23.75 23.75H6.25V6.25H23.75V23.875M23.75 3.75H6.25C4.875 3.75 3.75 4.875 3.75 6.25V23.75C3.75 25.125 4.875 26.25 6.25 26.25H23.75C25.125 26.25 26.25 25.125 26.25 23.75V6.25C26.25 4.875 25.125 3.75 23.75 3.75Z" fill="white"></path>
                        </svg>
                    </button>
                </div>
            </div>
        </div> 
    )
}