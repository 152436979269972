import {parseString} from '../../../../utils/date';
import Numeric from '../../../components/numeric';
import SportIcon from '../../../components/sport_icon';
import {useState} from 'react';

const calculateRate = (bets) => {
    let rate = 1;
    for(const i in bets) rate *= bets[i].pointer.oc_rate;
    return rate;
}

export default ({bet}) => {
    const [opened, setOpened] = useState(false);

    let bet_class = '';
    if(bet.status === 1) bet_class = 'bet__money__win';
    if(bet.status === 2) bet_class = 'bet__money__lose';

    return (
        <div class="bet__full">
            <div class="bet" style={{cursor: 'pointer'}} onClick={e => setOpened(!opened)}>
                <div class="bet__content">
                    <div class="bet__content__block">
                        <div class="bet__info">
                            <p class="bet__info__grey">ID {bet.betId}</p>
                            <p class="bet__info__white">{parseString(bet.timestamp)}</p>
                        </div>
                        <div class="bet__info bet__info__center">
                            <p class="bet__info__grey bet__info__bold ">Express</p>
                        </div>
                    </div>
                    <div class="bet__content__block">
                        <div class="bet__info">
                            <p class="bet__info__grey">Amount</p>
                            <p class="bet__info__white">
                                <Numeric value={bet.amount} displayType="text" thousandSeparator={' '} decimalSeparator={','} decimalScale={2} />
                            </p>
                        </div>
                        <div class="bet__info">
                            <p class="bet__info__grey">Rate</p>
                            <p class="bet__info__white">
                                <Numeric value={calculateRate(bet.bets)} displayType="text" prefix={'x'} thousandSeparator={' '} decimalSeparator={'.'} decimalScale={3} />
                            </p>
                        </div>
                    </div>
                    <div class="bet__content__block">
                        <div class="bet__info">
                            <p class="bet__info__grey">Potential</p>
                            <p class="bet__info__white">
                                <Numeric value={calculateRate(bet.bets) * bet.amount} displayType="text" thousandSeparator={' '} decimalSeparator={'.'} decimalScale={2} />
                            </p>
                        </div>
                    </div>
                </div>
                <div class="bet__money__block">
                    <div class={`bet__money ${bet_class}`}>
                        {bet.status === 0 && <p>PENDING</p>}
                        {bet.status === 1 && <p>+<Numeric value={bet.result} displayType="text" thousandSeparator={' '} decimalSeparator={'.'} decimalScale={2} /></p>}
                        {bet.status === 2 && <p>-<Numeric value={bet.amount} displayType="text" thousandSeparator={' '} decimalSeparator={'.'} decimalScale={2} /></p>}
                        {bet.status === 3 && <p>CANCELED</p>}
                    </div>
                    <button id="betOpen2" class="bet__open" style={{
                        transform: `rotate(${opened ? 180 : 0}deg)`
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none">
                            <path d="M9.73205 12C8.96225 13.3333 7.03775 13.3333 6.26795 12L1.0718 3C0.301995 1.66667 1.26425 -1.46309e-06 2.80385 -1.32849e-06L13.1961 -4.19966e-07C14.7358 -2.8537e-07 15.698 1.66667 14.9282 3L9.73205 12Z" fill="white"></path>
                        </svg>
                    </button>
                </div>
            </div>
            <div class={`bet__popup ${opened ? 'bet__popup_opened' : ''} bet__popup_lose`}>
                {bet.bets.map((b, key) => {
                    let b_class = '';
                    if(bet.status === 1) b_class = 'bet__money__win';
                    if(bet.status === 2) b_class = 'bet__money__lose';
                    
                    return (
                        <div class="bet bet__popup__opened bet__popup_red" key={key}>
                            <div class="bet__content">
                                <div class="bet__content__block">
                                    <div class="bet__info">
                                        <div class="bet__info__img">
                                            <SportIcon sportId={b.sport.id} />
                                        </div>
                                        <p class="bet__info__white">{b.championship.name}</p>
                                        <p class="bet__info__grey bet__info__bold">{b.event.name}</p>
                                    </div>
                                </div>
                                <div class="bet__content__block">
                                    <div class="bet__info">
                                        <p class="bet__info__grey">Pointer</p>
                                        <p class="bet__info__white">
                                            {b.pointer.oc_group_name} - {b.pointer.oc_name}
                                        </p>
                                    </div>
                                </div>
                                <div class="bet__money__block bet__money__block__single">
                                    <div class={`bet__money ${b_class}`}>
                                        <p>
                                            <Numeric value={b.pointer.oc_rate} displayType="text" prefix={'x'} thousandSeparator={' '} decimalSeparator={'.'} decimalScale={3} />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}