import NavigationUtil from '../../utils/navigation';
import {Link, useLocation} from 'react-router-dom';
import {useEffect} from 'react';
import {resetVisibleList} from '../../lib/websocket';
import {useSelector} from 'react-redux';
import Selector from '../../selectors/components/header';
import SportIcon from './sport_icon';
import Numeric from './numeric';

export default () => {
    const location = useLocation(),
          {sports, balance} = useSelector(Selector);

    useEffect(() => {
        NavigationUtil.setActive();
        NavigationUtil.toggle(false);
        resetVisibleList();
    }, [location]);

    return (
        <header class="header">
            <div class="header__container">
                <nav class="header__navigation">
                    <li class="header__navigation__item header__navigation__item_active">
                        <Link to={'/'} class="header__navigation__link">
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.0453 13.9807C25.4511 13.4459 24.5489 13.4459 23.9547 13.9807L11.4547 25.2307C11.1255 25.5271 10.9375 25.9491 10.9375 26.3921V35.9378C10.9375 36.8008 11.6371 37.5003 12.5 37.5003H17.6136C18.4766 37.5003 19.1761 36.8008 19.1761 35.9378V33.7503C19.1761 31.1614 21.2748 29.0628 23.8636 29.0628H26.1364C28.7252 29.0628 30.8239 31.1614 30.8239 33.7503V35.9378C30.8239 36.8008 31.5234 37.5003 32.3864 37.5003H37.5C38.363 37.5003 39.0625 36.8008 39.0625 35.9378V26.3921C39.0625 25.9491 38.8745 25.5271 38.5453 25.2307L26.0453 13.9807ZM21.8642 11.6579C23.6469 10.0535 26.3531 10.0535 28.1358 11.6579L40.6358 22.9078C41.6234 23.7969 42.1875 25.0633 42.1875 26.3921V35.9378C42.1875 38.5266 40.0889 40.6253 37.5 40.6253H32.3864C29.7975 40.6253 27.6989 38.5266 27.6989 35.9378V33.7503C27.6989 32.8874 26.9994 32.1878 26.1364 32.1878H23.8636C23.0006 32.1878 22.3011 32.8874 22.3011 33.7503V35.9378C22.3011 38.5266 20.2025 40.6253 17.6136 40.6253H12.5C9.91117 40.6253 7.8125 38.5266 7.8125 35.9378V26.3921C7.8125 25.0633 8.3765 23.7969 9.36423 22.9078L21.8642 11.6579Z" fill="white"></path>
                            </svg>
                        </Link>
                    </li>
                    <li class="header__navigation__item">
                        <Link to={'/live'} class="header__navigation__link" href="./live.html">
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.8125 12.5C4.36072 12.5 1.5625 15.2982 1.5625 18.75V31.25C1.5625 34.7017 4.36072 37.5 7.8125 37.5H42.1875C45.6392 37.5 48.4375 34.7017 48.4375 31.25V18.75C48.4375 15.2982 45.6392 12.5 42.1875 12.5H7.8125ZM4.6875 18.75C4.6875 17.0241 6.08661 15.625 7.8125 15.625H42.1875C43.9134 15.625 45.3125 17.0241 45.3125 18.75V31.25C45.3125 32.9759 43.9134 34.375 42.1875 34.375H7.8125C6.08661 34.375 4.6875 32.9759 4.6875 31.25V18.75ZM12.325 20.9375H9.375V29.6875H16.275V27.4H12.325V20.9375ZM21.0398 20.9375H18.0898V29.6875H21.0398V20.9375ZM32.81 20.9375L29.11 29.6875H26.21L22.51 20.9375H25.685L27.7725 26.05L29.91 20.9375H32.81ZM41.3786 29.6875V27.4625H37.0034V26.3125H40.7034V24.1875H37.0034V23.1625H41.2161V20.9375H34.1036V29.6875H41.3786Z" fill="white"></path>
                            </svg>
                        </Link>
                    </li>
                    <button id="OpenMenuMobile" class="header__navigation__item header__navigation__item__burger" onClick={e => NavigationUtil.toggle()}>
                        <div class="header__navigation__link" href="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                <path d="M7.8125 21.875C7.3981 21.875 7.00067 21.7104 6.70765 21.4174C6.41462 21.1244 6.25 20.7269 6.25 20.3125V7.81567C6.25 7.40127 6.41462 7.00385 6.70765 6.71082C7.00067 6.41779 7.3981 6.25317 7.8125 6.25317H20.3125C20.7269 6.25317 21.1243 6.41779 21.4174 6.71082C21.7104 7.00385 21.875 7.40127 21.875 7.81567V20.3125C21.875 20.7269 21.7104 21.1244 21.4174 21.4174C21.1243 21.7104 20.7269 21.875 20.3125 21.875H7.8125ZM29.6875 21.875C29.2731 21.875 28.8757 21.7104 28.5826 21.4174C28.2896 21.1244 28.125 20.7269 28.125 20.3125V7.81567C28.125 7.40127 28.2896 7.00385 28.5826 6.71082C28.8757 6.41779 29.2731 6.25317 29.6875 6.25317H42.1844C42.5988 6.25317 42.9962 6.41779 43.2892 6.71082C43.5823 7.00385 43.7469 7.40127 43.7469 7.81567V20.3125C43.7469 20.7269 43.5823 21.1244 43.2892 21.4174C42.9962 21.7104 42.5988 21.875 42.1844 21.875H29.6875ZM7.8125 43.7501C7.3981 43.7501 7.00067 43.5854 6.70765 43.2924C6.41462 42.9994 6.25 42.602 6.25 42.1876V29.6875C6.25 29.2731 6.41462 28.8757 6.70765 28.5827C7.00067 28.2897 7.3981 28.125 7.8125 28.125H20.3125C20.7269 28.125 21.1243 28.2897 21.4174 28.5827C21.7104 28.8757 21.875 29.2731 21.875 29.6875V42.1876C21.875 42.602 21.7104 42.9994 21.4174 43.2924C21.1243 43.5854 20.7269 43.7501 20.3125 43.7501H7.8125ZM29.6875 43.7501C29.2731 43.7501 28.8757 43.5854 28.5826 43.2924C28.2896 42.9994 28.125 42.602 28.125 42.1876V29.6875C28.125 29.2731 28.2896 28.8757 28.5826 28.5827C28.8757 28.2897 29.2731 28.125 29.6875 28.125H42.1844C42.5988 28.125 42.9962 28.2897 43.2892 28.5827C43.5823 28.8757 43.7469 29.2731 43.7469 29.6875V42.1876C43.7469 42.602 43.5823 42.9994 43.2892 43.2924C42.9962 43.5854 42.5988 43.7501 42.1844 43.7501H29.6875Z" fill="white"></path>
                            </svg>
                        </div>
                    </button>
                    {sports.filter((_, key) => key < 17).map((sport, key) => {
                        return (
                            <li class="header__navigation__item" key={key}>
                                <Link to={`/sports/${sport.sportId}`} class="header__navigation__link">
                                    <SportIcon sportId={sport.sportId} />
                                </Link>
                            </li>
                        )
                    })}
                    <button id="openMenu" class="header__navigation__button" onClick={e => NavigationUtil.toggle()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 13" fill="none">
                            <path d="M9.73205 12C8.96225 13.3333 7.03775 13.3333 6.26795 12L1.0718 3C0.301997 1.66667 1.26425 2.35161e-06 2.80385 2.48621e-06L13.1962 3.39473e-06C14.7358 3.52933e-06 15.698 1.66667 14.9282 3L9.73205 12Z" fill="white"></path>
                        </svg>
                    </button>
                </nav>
                <div class="header__stats">
                    <div class="header__stats__balance">
                        <p>
                            <Numeric value={balance} displayType="text" thousandSeparator={' '} decimalSeparator={','} decimalScale={2} />
                        </p>
                    </div>
                    <nav class="header__stats__navigation">
                        <li class="header__navigation__itemRight">
                            <Link to={'/profile'} class="header__navigation__link">
                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.8125 10.4687C7.8125 7.31764 10.3072 4.6875 13.4766 4.6875H27.1484C30.3178 4.6875 32.8125 7.31764 32.8125 10.4687V30.1562C32.8125 33.3073 30.3178 35.9375 27.1484 35.9375H13.4766C10.3072 35.9375 7.8125 33.3073 7.8125 30.1562V10.4687ZM13.4766 7.8125C12.1155 7.8125 10.9375 8.95995 10.9375 10.4687V30.1562C10.9375 31.665 12.1155 32.8125 13.4766 32.8125H27.1484C28.5095 32.8125 29.6875 31.665 29.6875 30.1562V10.4687C29.6875 8.95995 28.5095 7.8125 27.1484 7.8125H13.4766ZM34.9609 15.625C34.9609 14.7621 35.6605 14.0625 36.5234 14.0625H37.5C40.0889 14.0625 42.1875 16.1611 42.1875 18.75V40.625C42.1875 43.2139 40.0889 45.3125 37.5 45.3125H21.875C19.2861 45.3125 17.1875 43.2139 17.1875 40.625V39.5312C17.1875 38.6683 17.887 37.9688 18.75 37.9688C19.613 37.9688 20.3125 38.6683 20.3125 39.5312V40.625C20.3125 41.488 21.012 42.1875 21.875 42.1875H37.5C38.363 42.1875 39.0625 41.488 39.0625 40.625V18.75C39.0625 17.887 38.363 17.1875 37.5 17.1875H36.5234C35.6605 17.1875 34.9609 16.488 34.9609 15.625ZM15.625 25C14.7621 25 14.0625 25.6995 14.0625 26.5625C14.0625 27.4255 14.7621 28.125 15.625 28.125H25C25.863 28.125 26.5625 27.4255 26.5625 26.5625C26.5625 25.6995 25.863 25 25 25H15.625ZM14.0625 20.3125C14.0625 19.4495 14.7621 18.75 15.625 18.75H25C25.863 18.75 26.5625 19.4495 26.5625 20.3125C26.5625 21.1755 25.863 21.875 25 21.875H15.625C14.7621 21.875 14.0625 21.1755 14.0625 20.3125ZM15.625 12.5C14.7621 12.5 14.0625 13.1996 14.0625 14.0625C14.0625 14.9254 14.7621 15.625 15.625 15.625H25C25.863 15.625 26.5625 14.9254 26.5625 14.0625C26.5625 13.1996 25.863 12.5 25 12.5H15.625Z" fill="white"></path>
                                </svg>
                            </Link>
                        </li>
                        <li class="header__navigation__itemRight">
                            <Link to={'/search'} class="header__navigation__link" href="./search.html">
                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.625 21.875C15.625 15.8344 20.5219 10.9375 26.5625 10.9375C32.6031 10.9375 37.5 15.8344 37.5 21.875C37.5 27.9156 32.6031 32.8125 26.5625 32.8125C20.5219 32.8125 15.625 27.9156 15.625 21.875ZM26.5625 7.8125C18.7959 7.8125 12.5 14.1085 12.5 21.875C12.5 25.4572 13.8394 28.7264 16.0447 31.2095L8.27014 38.9839C7.65995 39.5942 7.65995 40.5834 8.27014 41.1938C8.88034 41.8039 9.86966 41.8039 10.4799 41.1938L18.3686 33.305C20.6758 34.9619 23.5052 35.9375 26.5625 35.9375C34.3291 35.9375 40.625 29.6416 40.625 21.875C40.625 14.1085 34.3291 7.8125 26.5625 7.8125Z" fill="white"></path>
                                </svg>
                            </Link>
                        </li>
                    </nav>
                </div>
            </div>
        </header>
    )
}