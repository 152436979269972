export default ({opened, setOpened, tickets}) => {
    const has_combine = tickets.filter(t => t.combined).length > 0;
    
    return (
        <button id="openTicketMobile" class="ticked__mobile" onClick={e => setOpened(true)}>
            <div class="ticked__mobile__img">
                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.41086 9.96072C6.91951 9.33788 8.61674 10.1607 9.68536 11.395C10.4532 12.2832 11.4073 12.9913 12.4798 13.4692C13.7548 14.0391 15.1563 14.2669 16.5461 14.1301C17.9359 13.9933 19.2661 13.4966 20.4055 12.689C21.5448 11.8815 22.454 10.7909 23.0433 9.52488C23.6326 8.25885 23.8817 6.86109 23.7661 5.46944C23.6404 3.90378 24.1947 2.18955 25.6233 1.52101L27.9663 0.418185C29.2827 -0.131366 30.7627 -0.139581 32.0851 0.395323C33.4075 0.930226 34.4655 1.96508 35.0295 3.27523L37.7954 9.66359C37.6903 9.876 37.5912 10.0913 37.4982 10.3093L37.4925 10.3207L19.263 52.4164L17.2858 56.9134L0.393454 17.829C-0.117162 16.5635 -0.131261 15.1519 0.353976 13.8764C0.839212 12.6009 1.78803 11.5555 3.01073 10.9493L5.41086 9.96072ZM70.1113 21.4118C71.1685 20.1661 72.8715 19.349 74.3801 19.9661L76.9117 21.0118C78.1655 21.634 79.1385 22.7063 79.6364 24.0144C80.1343 25.3225 80.1204 26.7703 79.5975 28.0687L58.6136 76.6385C58.0345 77.9836 56.9481 79.0459 55.5903 79.5947C54.2325 80.1436 52.713 80.1346 51.3618 79.5698L26.0005 68.7645C24.6557 68.1865 23.593 67.1016 23.0431 65.7451C22.4932 64.3887 22.5004 62.8701 23.0632 61.519L25.8119 55.2735L44.0586 13.1435C44.6366 11.7988 45.7216 10.7362 47.0781 10.1864C48.4347 9.63651 49.9534 9.64372 51.3047 10.2064L53.7848 11.3664C55.2134 12.0349 55.7677 13.7492 55.6249 15.3205C55.5181 16.5135 55.6718 17.7154 56.0754 18.8431C56.479 19.9708 57.1227 20.9973 57.9621 21.8517C58.7651 22.666 59.7287 23.3045 60.7915 23.7266C61.8544 24.1488 62.9934 24.3454 64.1363 24.3041C65.2793 24.2627 66.4011 23.9842 67.4306 23.4863C68.4602 22.9884 69.375 22.282 70.1171 21.4118H70.1113Z" fill="white"></path>
                </svg>
                {tickets.length > 0 && (
                    <>
                        {has_combine ? (
                            <div class="ticked__mobile__elips" style={{background: 'unset'}}>
                                <svg class="bt2320" data-cy="ic-betslip-error" width="16" height="16" viewBox="0 0 16 16" fill="F5A623" xmlns="http://www.w3.org/2000/svg" style={{fill: 'currentcolor', color: 'rgb(245, 166, 35)', width: 'auto', height: '16px'}}><path d="M9 4C9 3.44772 8.55228 3 8 3C7.44772 3 7 3.44772 7 4V9C7 9.55228 7.44772 10 8 10C8.55228 10 9 9.55228 9 9V4Z"></path><path d="M8 11C8.55228 11 9 11.4477 9 12C9 12.5523 8.55228 13 8 13C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11Z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"></path></svg>
                            </div>
                        ) : (
                            <div class="ticked__mobile__elips">
                                <p>{tickets.length}</p>
                            </div>
                        )}
                    </>
                )}
            </div>
        </button>
    )
}