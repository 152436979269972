import {useSelector} from 'react-redux';
import Selector from '../../selectors/components/header';
import {Link} from 'react-router-dom';
import NavigationUtil from '../../utils/navigation';
import SportIcon from './sport_icon';


export default () => {
    const {sports} = useSelector(Selector);

    return (
        <div id="headerMenu" class="header__modal" style={{display: 'none', overflow: 'scroll', height: '100%'}}>
            <div class="header__modal__container">
                <button class="header__modal__close" id="headerClose" style={{display: 'none'}} onClick={e => NavigationUtil.toggle()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M29.9993 33.5351L44.1418 47.6776C44.6133 48.133 45.2448 48.385 45.9003 48.3793C46.5558 48.3736 47.1828 48.1107 47.6464 47.6472C48.1099 47.1837 48.3728 46.5566 48.3785 45.9011C48.3842 45.2456 48.1322 44.6141 47.6768 44.1426L33.5343 30.0001L47.6768 15.8576C48.1322 15.3861 48.3842 14.7546 48.3785 14.0991C48.3728 13.4436 48.1099 12.8166 47.6464 12.3531C47.1828 11.8896 46.5558 11.6266 45.9003 11.6209C45.2448 11.6152 44.6133 11.8672 44.1418 12.3226L29.9993 26.4651L15.8568 12.3226C15.3832 11.8785 14.7553 11.636 14.1061 11.6466C13.4569 11.6571 12.8373 11.9198 12.3783 12.3791C11.9194 12.8384 11.6571 13.4582 11.647 14.1074C11.6369 14.7566 11.8798 15.3843 12.3243 15.8576L26.4643 30.0001L12.3218 44.1426C12.083 44.3732 11.8926 44.6491 11.7616 44.9541C11.6305 45.2591 11.5616 45.5872 11.5587 45.9191C11.5558 46.2511 11.6191 46.5803 11.7448 46.8875C11.8705 47.1948 12.0561 47.4739 12.2908 47.7086C12.5256 47.9433 12.8047 48.129 13.1119 48.2547C13.4192 48.3804 13.7484 48.4436 14.0803 48.4408C14.4123 48.4379 14.7403 48.3689 15.0453 48.2379C15.3503 48.1069 15.6262 47.9164 15.8568 47.6776L29.9993 33.5351Z" fill="white"></path>
                    </svg>
                </button>
                {sports.map((sport, key) => {
                    return (
                        <Link to={`/sports/${sport.sportId}`} class="header__modal__item">
                            <SportIcon sportId={sport.sportId} />
                            <p>{sport.name}</p>
                        </Link>
                    )
                })}
            </div>
        </div>
    )
}