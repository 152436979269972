import Match from '../../../components/match';
import {getCountToShow} from '../../../../utils/viewport';
import Tags from '../../../components/tags';
import {useDispatch} from 'react-redux';
import {updateMatches, getTopMatches} from '../../../../reducers/books/actions';

export default ({matches: mathes_info, tickets}) => {
    const count_to_show = getCountToShow(),
          dispatch = useDispatch(),
          matches = mathes_info.matches ? mathes_info.matches.filter((_, key) => key < count_to_show) : mathes_info.matches;

    return (
        <>
            <div class="subtitle container">
                <div class="subtitle__image">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                        <g opacity="0.7">
                            <path d="M35 45.833H15C14.18 45.833 13.5 45.1247 13.5 44.2705C13.5 43.4163 14.18 42.708 15 42.708H35C35.82 42.708 36.5 43.4163 36.5 44.2705C36.5 45.1247 35.82 45.833 35 45.833Z" fill="#FF9800"></path>
                            <path d="M41.7044 11.5L33.7044 17.4583C32.6444 18.25 31.1244 17.7708 30.6644 16.5L26.8844 6C26.2444 4.1875 23.7844 4.1875 23.1444 6L19.3443 16.4792C18.8843 17.7708 17.3843 18.25 16.3243 17.4375L8.32434 11.4792C6.72434 10.3125 4.60434 11.9583 5.26434 13.8958L13.5843 38.1666C13.8643 38.9999 14.6243 39.5416 15.4643 39.5416H34.5244C35.3644 39.5416 36.1244 38.9791 36.4044 38.1666L44.7244 13.8958C45.4044 11.9583 43.2844 10.3125 41.7044 11.5ZM30.0044 30.7291H20.0044C19.1843 30.7291 18.5043 30.0207 18.5043 29.1666C18.5043 28.3124 19.1843 27.6041 20.0044 27.6041H30.0044C30.8244 27.6041 31.5044 28.3124 31.5044 29.1666C31.5044 30.0207 30.8244 30.7291 30.0044 30.7291Z" fill="#FF9900"></path>
                        </g>
                    </svg>
                </div>
                <h2 class="subtitle__text">Top matches</h2>
            </div>
            {!mathes_info.matches ? (
                <div class="loader__fullBlock" style={{minHeight: '100px'}}>
                    <div class="loader__fullBlock__elem">
                        <span class="loader"></span>
                    </div>
                </div> 
            ) : (
                <>
                    <Tags sports={mathes_info.sports} current_tag={mathes_info.sportId} showCount={false} onClickHandler={value => {
                        dispatch(updateMatches('top_matches'));
                        dispatch(getTopMatches(value));
                    }} />
                    <div class="container">
                        <div 
                            className="match__slider">
                            {matches.map((match, key) => <Match match={match} key={key} isLive={match.type === 'live'} tickets={tickets} />)}
                        </div>
                    </div>
                </>
            )}
        </>
    )
}