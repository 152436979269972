export default () => (
    <div class="container" style={{
        height: 'calc(100vh - 100px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    }}>
        <div class="search__empty">
            <div class="search__empty__img">
                <svg xmlns="http://www.w3.org/2000/svg" width="177" height="182" viewBox="0 0 177 182" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M42.1428 75.6342C42.1428 43.1451 68.558 16.8076 101.143 16.8076C133.728 16.8076 160.143 43.1451 160.143 75.6342C160.143 108.123 133.728 134.461 101.143 134.461C68.558 134.461 42.1428 108.123 42.1428 75.6342ZM101.143 0C59.2478 0 25.2856 33.8626 25.2856 75.6342C25.2856 94.9007 32.5106 112.484 44.4068 125.839L2.46864 167.653C-0.822881 170.936 -0.822881 176.256 2.46864 179.539C5.76025 182.82 11.0969 182.82 14.3885 179.539L56.9426 137.11C69.3882 146.021 84.6507 151.268 101.143 151.268C143.038 151.268 177 117.406 177 75.6342C177 33.8626 143.038 0 101.143 0Z" fill="#737A87"></path>
                </svg>
            </div>
            <p class="search__empty__question search__empty__question_1">?</p>
            <p class="search__empty__question search__empty__question_2">?</p>
            <p class="search__empty__question search__empty__question_3">?</p>
            <p class="search__empty__question search__empty__question_4">?</p>
        </div>
        <p class="search__empty__text">Could not find events according to your request</p>
    </div>
)