import {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Selector from '../../../selectors/components/tickets';

import Modal from './modal';
import OpenButton from './openButton';

export default () => {
    const [opened, setOpened] = useState(false),
          {tickets, button} = useSelector(Selector),
          location = useLocation();

    const eventHandler = () => setOpened(false);

    useEffect(() => {
        if(opened) {
            if(document.body.offsetWidth < 1000)
                document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
    }, [opened]);
    
    useEffect(() => {
        setOpened(false);
    }, [location]);

    useEffect(() => {
        document.addEventListener('tickets.close', eventHandler);
        return () => document.removeEventListener('tickets.close', eventHandler);
    }, []);

    return (
        <>
            <Modal opened={opened} setOpened={setOpened} tickets={tickets} button={button} />
            <OpenButton opened={opened} setOpened={setOpened} tickets={tickets} />
        </>
    )
}